import React from "react";
import DescriptionText from "../DescriptionText";

function PaymentOptions() {
  return (
    <div>
      <DescriptionText
        text={
          "Both online payments and Cash on Delivery (COD) payments can be made using credit/debit cards, UPI, or net banking."
        }
      />
      <DescriptionText
        text={
          "If an online payment fails but the amount is debited, it will be refunded within 5–7 business days."
        }
      />
    </div>
  );
}

export default PaymentOptions;
