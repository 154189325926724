import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../components/CustomAccordion";
import TextComp from "../../../../components/TextComp";
import { FlexDiv } from "..";

import SelectInput from "../../../../components/TextInput/SelectInput";
import DialogBox from "../../../../components/DialogBox";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import Button from "../../../../components/Button";
import {
  returnCancelStatus,
  returnCancelStatusValues,
} from "../../../../constants/ReturnCancelStatus";
import DisplayProducts from "../../../ProfileMenu/MyAccount/MyOrders/DisplayProducts";

function ReturnDetails({ item, setLoading, setOrderData, index }) {
  const [updateReturnStatus, setUpdateReturnStatus] = useState(
    item?.returnData?.returnStatus
  );

  const [updateReturnStatusDialogBox, setUpdateReturnStatusDialogBox] =
    useState(false);

  const [returnProducts, setReturnProducts] = useState(null);

  const updateReturnStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updateReturnStatus",
      data: {
        orderId: item?.orderId,
        returnStatus: updateReturnStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setOrderData((prevOrderData) =>
            prevOrderData.map((order, i) =>
              i === index
                ? {
                    ...order,
                    returnData: {
                      ...order.returnData,
                      returnStatus: updateReturnStatus,
                    },
                  }
                : order
            )
          );

          successToastify(SuccessTexts.returnStatusUpdate);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdateReturnStatusDialogBox(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    const returnFilterProducts = item?.bagData?.collectiveBagData?.filter(
      (products) =>
        item?.returnData?.productIds?.some(
          (returnIds) =>
            products?.id === returnIds?.productId &&
            products?.variants?.id === returnIds?.variantId
        )
    );

    setReturnProducts(returnFilterProducts);
  }, []);

  return (
    <div className="py-2">
      <CustomAccordion
        title={"Return Details"}
        details={
          <div>
            <FlexDiv
              title={"Return Status"}
              value={item?.returnData?.returnStatus}
            />

            {returnProducts?.map((productItem, index) => (
              <DisplayProducts
                key={index}
                productItem={productItem}
                orderData={item}
              />
            ))}

            {item?.returnData?.returnStatus ===
            returnCancelStatusValues?.pending ? (
              <div className="my-5">
                <SelectInput
                  label={"Update return status"}
                  values={returnCancelStatus}
                  id="demo-controlled-open-select"
                  onChange={(e) => setUpdateReturnStatus(e.target.value)}
                  defaultValue={updateReturnStatus}
                />
                <Button
                  btnText={"UPDATE RETURN STATUS"}
                  customStyle={
                    item?.returnData?.returnStatus === updateReturnStatus
                      ? "my-2 bg-darkGray border-darkGray"
                      : "my-2"
                  }
                  onPress={() =>
                    item?.returnData?.returnStatus === updateReturnStatus
                      ? null
                      : setUpdateReturnStatusDialogBox(true)
                  }
                />
              </div>
            ) : null}
          </div>
        }
      />

      <DialogBox
        title={
          "Approve the status only after order has been returned to your warehouse."
        }
        open={updateReturnStatusDialogBox}
        setOpen={setUpdateReturnStatusDialogBox}
        agreeFunc={updateReturnStatusFunc}
      />
    </div>
  );
}

export default ReturnDetails;
