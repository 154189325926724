import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Colors } from "../../../constants/Colors";

function SelectInput({
  label,
  values,
  onChange,
  id = "",
  defaultValue = "",
  formikError,
  error,
}) {
  return (
    <FormControl fullWidth error={error || false}>
      <InputLabel
        id="demo-simple-select-label"
        sx={{
          "&.Mui-focused": {
            color: Colors.black,
            fontSize: 17,
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        defaultValue={defaultValue}
        // value={selectedValue}
        label={label}
        // onChange={(e) => setSelectedValue(e.target.value)}
        sx={{
          fontSize: 16,
          "& .MuiOutlinedInput-notchedOutline": {
            color: Colors.black,
            borderColor: Colors.black,
          },

          "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              color: Colors.red,
              borderColor: Colors.red,
            },
          },

          "&.Mui-focused": {
            fontSize: 17,
            "&.Mui-error": {
              "& .MuiOutlinedInput-notchedOutline": {
                color: Colors.red,
                borderColor: Colors.red,
              },
            },

            "& .MuiOutlinedInput-notchedOutline": {
              color: Colors.black,
              borderColor: Colors.black,
            },
          },
        }}
        onChange={onChange}
      >
        {values.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            sx={{
              "&:hover": {
                backgroundColor: Colors.lightGray,
              },
              "&:focus": {
                backgroundColor: Colors.lightGray,
                "&:hover": {
                  backgroundColor: Colors.lightGray,
                },
              },
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {formikError || null}
    </FormControl>
  );
}

export default SelectInput;
