import React, { useEffect, useState } from "react";
import ScrollToTop from "./Utilities/ScrollToTop";
import { Outlet, RouterProvider } from "react-router-dom";
import { router } from "./Utilities/Router";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch, useSelector } from "react-redux";
import { setData } from "./config/Redux/UserData";
import { RoutesFetch } from "./config/RoutesFetch";
import { setBagCount } from "./config/Redux/BagData";
import { useCookies } from "react-cookie";
import { VerifyTokenFunc } from "./config/VerifyTokenFunc";

function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData?.data);

  const [loading, setLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["userLoggedIn"]);

  useEffect(() => {
    VerifyTokenFunc({
      setLoading,
      dispatch,
      cookies,
    });
  }, []);

  useEffect(() => {
    RoutesFetch({
      method: "get",
      url: "/getBagItemLength",
    })
      .then((response) => {
        if (response.msg !== "fail") {
          dispatch(setBagCount(response.count));
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, [userData]);

  return <RouterProvider router={router({ userData, loading })} />;
}

export const AppChild = () => {
  return (
    // <React.StrictMode>
    <>
      <div>
        <Outlet />
      </div>
      <ScrollToTop />
      <ToastContainer />
    </>
    // {/* </React.StrictMode> */}
  );
};

export default App;
