export const Colors = {
  white: "#fff",
  black: "#000",
  lightGray: "#EEEEEE",
  darkGray: "#717481",
  yellow: "rgb(214,193,0)",
  red: "#d32f2f",
  rosePink: "#FF007F",
  green: "rgb(74,163,135)",
  warningYellow: "#ee9f27",
  themeBlue: "#112fc0",
};
