import React, { useEffect, useState } from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import TextComp from "../../../components/TextComp";
import { PageHeader } from "../../../components/PageHeader";

import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import RouteWaiter from "../../../components/RouteWaiter";
import { CiEdit } from "react-icons/ci";
import { PiTrashSimple } from "react-icons/pi";

import { Colors } from "../../../constants/Colors";
import DialogBox from "../../../components/DialogBox";
import { errorToastify, successToastify } from "../../../components/Toastify";
import { SuccessTexts } from "../../../constants/SuccessTexts";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";

function Offers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [discountData, setDiscountData] = useState(null);
  const [errorText, setErrorText] = useState("");

  return (
    <NavbarAndFooterComp
      children={
        <div>
          <PageHeader text={"ALL COUPONS"} />

          <div className="my-10 mx-5 md:mx-10">
            <div className="flex flex-row justify-end">
              <div className="w-full md:w-fit">
                <Button
                  btnText={"Create Coupon"}
                  onPress={() => {
                    navigate("/adminCreateCoupon");
                  }}
                />
              </div>
            </div>

            <InfiniteScrollComp
              url="/getAllCouponCollection"
              responseDataName="discountData"
              setLoading={setLoading}
              setErrorText={setErrorText}
              data={discountData}
              setData={setDiscountData}
              valueOfUseEffectArray={[]}
              children={
                <RouteWaiter
                  loading={loading}
                  error={errorText}
                  data={discountData}
                  children={
                    <div>
                      {discountData?.map((item, index) => (
                        <DiscountCardComp
                          key={index}
                          item={item}
                          navigate={navigate}
                          discountData={discountData}
                          index={index}
                          setDiscountData={setDiscountData}
                        />
                      ))}
                    </div>
                  }
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}

const DiscountCardComp = ({
  item,
  navigate,
  discountData,
  setDiscountData,
  index,
}) => {
  const [deleteCouponDialog, setDeleteCouponDialog] = useState(false);

  const deleteCoupon = () => {
    RoutesFetch({
      method: "post",
      url: "/deleteCouponCollection",
      data: {
        couponId: item.couponId,
      },
    })
      .then((response) => {
        if (response.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          discountData.splice(index, 1);
          setDiscountData([...discountData]);
          successToastify(SuccessTexts.couponDeleted);
          setDeleteCouponDialog(false);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      });
  };

  return (
    <div className="border-black border rounded my-5 p-5 flex flex-row items-center justify-between">
      <div>
        <TextComp
          text={item?.couponName}
          customStyle={"text-lg tracking-wide font-medium"}
        />
        <TextComp
          text={item?.couponCode}
          customStyle={"text-xs tracking-wide font-light"}
        />
      </div>

      <div className="flex flex-row items-center">
        <CiEdit
          size={30}
          color={Colors.themeBlue}
          className="cursor-pointer"
          onClick={() => {
            navigate("/adminCreateCoupon", {
              state: {
                couponId: item.couponId,
              },
            });
          }}
        />

        <PiTrashSimple
          size={30}
          color={Colors.red}
          className="ml-5 cursor-pointer"
          onClick={() => {
            setDeleteCouponDialog(true);
          }}
        />
      </div>

      <DialogBox
        open={deleteCouponDialog}
        setOpen={setDeleteCouponDialog}
        title={"Are you sure you'd like to remove this coupon?"}
        agreeFunc={deleteCoupon}
      />
    </div>
  );
};

export default Offers;
