export const CouponTypesValues = {
  fixed: "fixed",
  percentage: "percentage",
  flat: "flat",
  buy_x_get_y: "buy_x_get_y",
};

export const CouponTypes = [
  { label: "Fixed Amount", value: CouponTypesValues?.fixed },
  { label: "Percentage", value: CouponTypesValues?.percentage },
  { label: "Flat Amount", value: CouponTypesValues?.flat },
  { label: "Buy X Get Y", value: CouponTypesValues?.buy_x_get_y },
];

export const BooleanTypesValues = {
  true: "true",
  false: "false",
};

export const BooleanTypes = [
  { label: "True", value: BooleanTypesValues.true },
  { label: "False", value: BooleanTypesValues.false },
];

export const ProductCollectionSpecificTypesValues = {
  product_specific: "product_specific",
  collection_specific: "collection_specific",
  all_products: "all_products",
};

export const ProductCollectionSpecificTypes = [
  {
    label: "Product Specific",
    value: ProductCollectionSpecificTypesValues.product_specific,
  },
  {
    label: "Collection Specific",
    value: ProductCollectionSpecificTypesValues.collection_specific,
  },
  {
    label: "All Products",
    value: ProductCollectionSpecificTypesValues.all_products,
  },
];

export const CustomerSpecificTypesValues = {
  customer_specific: "customer_specific",
  all_customers: "all_customers",
};

export const CustomerSpecificTypes = [
  {
    label: "Customer Specific",
    value: CustomerSpecificTypesValues.customer_specific,
  },
  { label: "All Customers", value: CustomerSpecificTypesValues.all_customers },
];

export const minimumRequirementTypesValues = {
  minimum_amount_bag: "minimum_amount_bag",
  minimum_quantity_bag: "minimum_quantity_bag",
  no_minimum_requirement: "no_requirement",
};

export const minimumRequirementTypes = [
  {
    label: "Minimum Amount In Bag",
    value: minimumRequirementTypesValues.minimum_amount_bag,
  },
  {
    label: "Minimum Quantity In",
    value: minimumRequirementTypesValues.minimum_quantity_bag,
  },
  {
    label: "No Minimum Requirement",
    value: minimumRequirementTypesValues.no_minimum_requirement,
  },
];

export const rewardGivenASTypesValues = {
  percentage: "percentage",
  fixed: "fixed",
  flat: "flat",
  free: "free",
};

export const rewardGivenASTypes = [
  { label: "Fixed Amount", value: rewardGivenASTypesValues.fixed },
  { label: "Percentage", value: rewardGivenASTypesValues.percentage },
  { label: "Flat Amount", value: rewardGivenASTypesValues?.flat },
  { label: "Free", value: rewardGivenASTypesValues.free },
];
