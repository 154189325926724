import { errorToastify } from "../../../../components/Toastify";

import { RoutesFetch } from "../../../../config/RoutesFetch";

import { ErrorTexts } from "../../../../constants/ErrorTexts";

import { setBagCount } from "../../../../config/Redux/BagData";
import {
  paymentMethodTypeValues,
  paymentStatus,
} from "../../../../constants/PaymentStatus";

///// adress function ////

export const getAddress = ({ setAddressLoading, setDeliveryAddress }) => {
  setAddressLoading(true);

  RoutesFetch({
    method: "get",
    url: "/getDefaultAddress",
  })
    .then((res) => {
      if (res.msg === "fail") {
        errorToastify(ErrorTexts.sww);
      } else {
        setDeliveryAddress(res?.addressData);
      }
    })
    .catch(() => {
      errorToastify(ErrorTexts.sww);
    })
    .finally(() => {
      setAddressLoading(false);
    });
};

//  payment Function //

export const proceedToCheckout = async ({
  orderId,
  setLoading,
  deliveryAddress,
  bagData,
  setUpdateBagData,
  updateBagData,
  userData,
  paymentType,
  dispatch,
  setOrderSuccess,
  couponData,
  setCouponCode,
  setCheckPaymentStatus,
}) => {
  const checkoutData = {
    orderId,
    setLoading,
    bagData,
    paymentType,
    couponData,
    deliveryAddress,
  };

  const createOrderData = {
    setLoading,
    deliveryAddress,
    bagData,
    setUpdateBagData,
    updateBagData,
    paymentType,
    dispatch,
    setOrderSuccess,
    couponData,
    setCouponCode,
  };

  if (deliveryAddress === null) {
    errorToastify(ErrorTexts.selectAddress);
  } else {
    if (paymentType === paymentMethodTypeValues?.cod) {
      createCheckout(checkoutData)
        .then((checkoutDataRes) => {
          createOrder({
            ...createOrderData,
            orderId: checkoutDataRes?.checkoutData?.orderId,
            paymentStatus: paymentStatus?.PENDING,
          });
        })
        .catch(() => {
          errorToastify(ErrorTexts.sww);
        });
    } else {
      onlinePayment({
        totalAmountToPaid: bagData?.totalAmountToPaid,
        userData,
        checkoutData,
        createOrderData,
        setCheckPaymentStatus,
      });
    }
  }
};

///////////////////////

const createCheckout = ({
  orderId,
  setLoading,
  bagData,
  paymentType,
  paymentOrderId,
  deliveryAddress,
  couponData,
}) => {
  setLoading(true);

  return new Promise((resolve, reject) => {
    RoutesFetch({
      method: "post",
      url: "/createCheckoutCollection",
      data: {
        orderId,
        bagId: bagData?.bagId,
        paymentMethodType: paymentType,
        paymentOrderId,
        bagData,
        deliveryAddress,
        couponData,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          reject();
        } else {
          resolve(res);
        }
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        setLoading(false);
      });
  });
};

///////////////////////

const createOrder = ({
  setLoading,
  deliveryAddress,
  bagData,
  setUpdateBagData,
  updateBagData,
  paymentType,
  dispatch,
  setOrderSuccess,
  couponData,
  setCouponCode,
  orderId,
  paymentStatus,
  paymentDetails,
}) => {
  setLoading(true);

  RoutesFetch({
    method: "post",
    url: "/createOrderCollection",
    data: {
      bagId: bagData?.bagId,
      bagData,
      couponData,
      deliveryAddress,
      paymentMethodType: paymentType,
      orderId,
      paymentStatus,
      paymentDetails,
    },
  })
    .then((res) => {
      if (res.msg === "fail") {
        errorToastify(ErrorTexts.sww);
      } else {
        orderCompletedUpdates({
          setUpdateBagData,
          updateBagData,
          setOrderSuccess,
          dispatch,
          setCouponCode,
        });
      }
    })
    .catch((err) => {
      errorToastify(ErrorTexts.sww);
    })
    .finally(() => {
      setLoading(false);
    });
};

/////////////////////////////

const orderCompletedUpdates = ({
  setUpdateBagData,
  updateBagData,
  setOrderSuccess,
  dispatch,
  setCouponCode,
}) => {
  setUpdateBagData(updateBagData + 1);
  setCouponCode("");
  setOrderSuccess(true);
  dispatch(setBagCount(0));
};

/////////////////////////////

const onlinePayment = ({
  totalAmountToPaid,
  userData,
  checkoutData,
  createOrderData,
  setCheckPaymentStatus,
}) => {
  RoutesFetch({
    method: "get",
    url: "/payment",
    params: {
      price: totalAmountToPaid,
    },
  })
    .then((res) => {
      if (res.msg === "fail") {
        errorToastify(ErrorTexts.sww);
      } else {
        createCheckout({ ...checkoutData, paymentOrderId: res?.orderId })
          .then((checkoutDataRes) => {
            if (checkoutDataRes.msg === "fail") {
              errorToastify(ErrorTexts.sww);
            } else {
              setCheckPaymentStatus(true);

              const options = {
                name: "geniease",
                order_id: res?.orderId,
                prefill: {
                  name: userData?.firstName + userData?.lastName,
                  email: userData?.email,
                  contact: userData?.mobileNumber,
                },

                handler: function (response) {
                  setCheckPaymentStatus(false);
                  createOrder({
                    ...createOrderData,
                    orderId: checkoutDataRes?.checkoutData?.orderId,
                    paymentDetails: response,
                    paymentStatus: paymentStatus?.SUCCESS,
                  });
                },
              };

              const rzp = new window.Razorpay(options);
              rzp.open();

              rzp.on("payment.failed", function (response) {
                setCheckPaymentStatus(false);
                errorToastify(ErrorTexts?.paymentFailed);
              });
              rzp.on("modal.ondismiss", function (response) {
                setCheckPaymentStatus(false);
                errorToastify(ErrorTexts?.paymentCancelled);
              });
            }
          })
          .catch((err) => {
            errorToastify(ErrorTexts.sww);
          });
      }
    })
    .catch((err) => {
      errorToastify(ErrorTexts.sww);
    });
};

/////////////////////

export const checkPaymentStatusFunc = ({
  orderId,
  setUpdateBagData,
  updateBagData,
  dispatch,
  setOrderSuccess,
  setCouponCode,
  setCheckPaymentStatus,
}) => {
  RoutesFetch({
    method: "get",
    url: "/getCheckoutCollection",
    params: {
      orderId,
    },
  })
    .then((checkoutDataRes) => {
      if (checkoutDataRes.msg === "fail") {
      } else {
        if (
          checkoutDataRes?.checkoutData?.paymentStatus ===
          paymentStatus?.SUCCESS
        ) {
          setCheckPaymentStatus(false);
          orderCompletedUpdates({
            setUpdateBagData,
            updateBagData,
            setOrderSuccess,
            dispatch,
            setCouponCode,
          });
        } else if (
          checkoutDataRes?.checkoutData?.paymentStatus === paymentStatus?.FAILED
        ) {
          setCheckPaymentStatus(false);
          errorToastify(ErrorTexts?.paymentFailed);
        } else if (
          checkoutDataRes?.checkoutData?.paymentStatus === paymentStatus?.REFUND
        ) {
          setCheckPaymentStatus(false);
          errorToastify(ErrorTexts?.paymentDispute);
        }
      }
    })
    .catch(() => {});
};

// ////////////////////////

// const updatePaymentDetails = ({ orderId, paymentStatus, paymentDetails }) => {
//   RoutesFetch({
//     method: "post",
//     url: "/updateOnlinePaymentDetails",
//     data: {
//       orderId,
//       paymentStatus,
//       paymentDetails,
//     },
//   })
//     .then(() => {})
//     .catch(() => {});
// };
