import React, { useState, useEffect } from "react";
import TextComp from "../../../../../../components/TextComp";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";

import Button from "../../../../../../components/Button";
import { returnReasons } from "../../../../../../constants/ReturnCancelReasons";
import DisplayProducts from "../../DisplayProducts";
import CustomCheckbox from "../../../../../../components/Checkbox";
import Loader from "../../../../../../components/Loader";
import { RoutesFetch } from "../../../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../../../components/Toastify";
import { ErrorTexts } from "../../../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../../../constants/SuccessTexts";

function ReturnForm({ setOpen, orderData, setUpdateDetails }) {
  const [returnReasonValue, setReturnReasonValue] = useState(null);
  const [returnedProducts, setReturnedProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  const sendReturnRequest = () => {
    RoutesFetch({
      method: "post",
      url: "/createReturn",
      data: {
        orderId: orderData?.orderId,
        productIds: returnedProducts,
        returnReason: returnReasonValue,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          successToastify(SuccessTexts?.returnRequest);
          setOpen(false);
          setUpdateDetails((prevValue) => {
            return prevValue + 1;
          });
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mx-4 mb-4">
      <CustomRadioGroup
        title={"Select Reason"}
        data={returnReasons}
        value={returnReasonValue}
        setValue={setReturnReasonValue}
      />

      <div className="bg-lightGray my-4 -mx-2">
        <TextComp
          text={"Select product to return"}
          customStyle={"p-2 text-sm font-medium underline"}
        />

        {orderData?.bagData?.collectiveBagData?.map((productItem, index) => (
          <SelectProducts
            key={index}
            setReturnedProducts={setReturnedProducts}
            orderData={orderData}
            productItem={productItem}
          />
        ))}
      </div>

      <div className="mt-4">
        {returnReasonValue ? (
          <Button btnText="SUBMIT" onPress={sendReturnRequest} />
        ) : (
          <Button
            btnText="SUBMIT"
            customStyle={"bg-darkGray border-darkGray "}
          />
        )}
      </div>

      <Loader loading={loading} />
    </div>
  );
}

const SelectProducts = ({ productItem, orderData, setReturnedProducts }) => {
  const [select, setSelect] = useState(false);

  useEffect(() => {
    setReturnedProducts((prevReturnedProducts) => {
      if (select) {
        return prevReturnedProducts?.length
          ? [
              ...prevReturnedProducts,
              {
                productId: productItem?.id,
                variantId: productItem?.variants?.id,
              },
            ]
          : [
              {
                productId: productItem?.id,
                variantId: productItem?.variants?.id,
              },
            ];
      } else {
        return (
          prevReturnedProducts?.filter(
            (item) => item?.variantId !== productItem?.variants?.id
          ) || []
        );
      }
    });
  }, [select]);

  return (
    <div className="flex">
      <CustomCheckbox
        value={select}
        onChange={(e) => {
          setSelect(!select);
        }}
      />

      <DisplayProducts productItem={productItem} orderData={orderData} />
    </div>
  );
};

export default ReturnForm;
