import React, { useState } from "react";
import CustomAccordion from "../../../../components/CustomAccordion";
import TextComp from "../../../../components/TextComp";
import { FlexDiv } from "..";

import SelectInput from "../../../../components/TextInput/SelectInput";
import DialogBox from "../../../../components/DialogBox";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import Button from "../../../../components/Button";
import {
  paymentMethodTypeValues,
  paymentStatus,
  paymentStatusLabels,
} from "../../../../constants/PaymentStatus";

function PaymentDetails({ item, setLoading, setOrderData, index }) {
  const [updatePaymentStatus, setUpdatePaymentStatus] = useState(
    item?.paymentData?.paymentStatus
  );
  const [updatePaymentStatusDialogBox, setUpdatePaymentStatusDialogBox] =
    useState(false);

  const updatePaymentStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updatePaymentStatusCollection",
      data: {
        orderId: item?.orderId,
        paymentStatus: updatePaymentStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setOrderData((prevOrderData) =>
            prevOrderData.map((order, i) =>
              i === index
                ? {
                    ...order,
                    paymentData: {
                      ...order.paymentData,
                      paymentStatus: updatePaymentStatus,
                    },
                  }
                : order
            )
          );

          successToastify(SuccessTexts.paymentStatusUpdated);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdatePaymentStatusDialogBox(false);
        setLoading(false);
      });
  };

  return (
    <div className="py-2">
      <CustomAccordion
        title={"Pricing Details"}
        details={
          <div>
            <FlexDiv
              title={"Payment Type"}
              value={item?.paymentData?.paymentMethodType}
            />
            <FlexDiv title={"Payment Status"} value={updatePaymentStatus} />

            {item?.paymentDetails === paymentMethodTypeValues?.online ? (
              <>
                <FlexDiv
                  title={"Payment Id"}
                  value={item?.paymentData?.paymentDetails?.razorpay_payment_id}
                />
                <FlexDiv
                  title={"Order Id"}
                  value={item?.paymentData?.paymentDetails?.razorpay_order_id}
                />
              </>
            ) : null}
            <FlexDiv title={"Total MRP"} value={item?.bagData?.totalMrp} />
            <FlexDiv
              title={"Total Discount"}
              value={item?.bagData?.discountOnMrp}
            />
            {item?.bagData?.totalCouponDiscount && (
              <FlexDiv
                title={"Coupon Discount"}
                value={item?.bagData?.totalCouponDiscount}
              />
            )}
            <FlexDiv
              title={"Total Amount"}
              value={item?.bagData?.totalAmountToPaid}
            />

            {/* {updatePaymentStatus !== paymentStatus?.SUCCESS ? (
            <Button
              btnText={"PAYMENT COMPLETED"}
              customStyle={"my-2"}
              onPress={() => setUpdatePaymentStatusDialogBox(true)}
            />
          ) : null} */}

            {item?.paymentData?.paymentStatus !== paymentStatus?.SUCCESS ? (
              <div className="my-5">
                <SelectInput
                  label={"Update payment status"}
                  values={paymentStatusLabels}
                  id="demo-controlled-open-select"
                  onChange={(e) => setUpdatePaymentStatus(e.target.value)}
                  defaultValue={updatePaymentStatus}
                />
                <Button
                  btnText={"UPDATE PAYMENT STATUS"}
                  customStyle={
                    item?.paymentData?.paymentStatus === updatePaymentStatus
                      ? "my-2 bg-darkGray border-darkGray"
                      : "my-2"
                  }
                  onPress={() =>
                    item?.paymentData?.paymentStatus === updatePaymentStatus
                      ? null
                      : setUpdatePaymentStatusDialogBox(true)
                  }
                />
              </div>
            ) : null}
          </div>
        }
      />

      <DialogBox
        title={
          "Kindly ensure that the payment for this order has been completed before proceeding."
        }
        open={updatePaymentStatusDialogBox}
        setOpen={setUpdatePaymentStatusDialogBox}
        agreeFunc={updatePaymentStatusFunc}
      />
    </div>
  );
}

export default PaymentDetails;
