import React, { useState } from "react";
import TextComp from "../../../../../../components/TextComp";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";
import { cancelReasons } from "../../../../../../constants/ReturnCancelReasons";
import Button from "../../../../../../components/Button";
import { RoutesFetch } from "../../../../../../config/RoutesFetch";
import { ErrorTexts } from "../../../../../../constants/ErrorTexts";
import {
  errorToastify,
  successToastify,
} from "../../../../../../components/Toastify";
import { SuccessTexts } from "../../../../../../constants/SuccessTexts";
import Loader from "../../../../../../components/Loader";

function CancellationForm({ setOpen, orderData, setUpdateDetails }) {
  const [cancellationReasonValue, setCancellationReasonValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendCancelRequest = () => {
    RoutesFetch({
      method: "post",
      url: "/createCancellation",
      data: {
        orderId: orderData?.orderId,
        cancellationReason: cancellationReasonValue,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          successToastify(SuccessTexts?.cancellationRequest);
          setOpen(false);
          setUpdateDetails((prevValue) => {
            return prevValue + 1;
          });
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mx-4 mb-4">
      <CustomRadioGroup
        title={"Select Reason"}
        data={cancelReasons}
        value={cancellationReasonValue}
        setValue={setCancellationReasonValue}
      />

      <div className="mt-4">
        {cancellationReasonValue ? (
          <Button btnText="SUBMIT" onPress={sendCancelRequest} />
        ) : (
          <Button
            btnText="SUBMIT"
            customStyle={"bg-darkGray border-darkGray "}
          />
        )}
      </div>

      <Loader loading={loading} />
    </div>
  );
}

export default CancellationForm;
