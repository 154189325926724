export const paymentStatus = {
  FAILED: "failed",
  SUCCESS: "success",
  REFUND: "refund",
  PENDING: "pending",
};

export const paymentStatusLabels = [
  { label: "Failed", value: paymentStatus?.FAILED },
  { label: "Success", value: paymentStatus?.SUCCESS },
  { label: "Refund", value: paymentStatus?.REFUND },
  { label: "Pending", value: paymentStatus?.PENDING },
];

export const paymentStatusFilterValues = {
  All: "all",
  FAILED: "failed",
  SUCCESS: "success",
  REFUND: "refund",
  PENDING: "pending",
};

export const paymentStatusFilter = [
  { label: "All", value: paymentStatusFilterValues?.All },
  { label: "Failed", value: paymentStatusFilterValues?.FAILED },
  { label: "Success", value: paymentStatusFilterValues?.SUCCESS },
  { label: "Refund", value: paymentStatusFilterValues?.REFUND },
  { label: "Pending", value: paymentStatusFilterValues?.PENDING },
];

export const paymentMethodTypeValues = {
  online: "online",
  cod: "cod",
};
