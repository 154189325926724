import React, { useState } from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import SelectInput from "../../../components/TextInput/SelectInput";
import {
  CustomisationStatusesFilter,
  CustomisationStatusesValuesFilter,
} from "../../../constants/DeliveryStatuses";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";
import RouteWaiter from "../../../components/RouteWaiter";
import TextComp from "../../../components/TextComp";
import UserDetails from "./UserDetails";
import CustomisationDetails from "./CustomisationDetails";

function CustomisationRequest() {
  const [filter, setFilter] = useState(CustomisationStatusesValuesFilter?.all);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [customisationData, setCustomisationData] = useState(null);

  return (
    <NavbarAndFooterComp
      children={
        <div className="mx-5 md:mx-10 py-10">
          <SelectInput
            label={"Search by customisation status"}
            values={CustomisationStatusesFilter}
            id="title"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={filter}
          />

          <div>
            <InfiniteScrollComp
              url="/getAllCustomisation"
              responseDataName="customisationData"
              params={{ customisationStatus: filter }}
              setLoading={setLoading}
              setErrorText={setErrorText}
              data={customisationData}
              setData={setCustomisationData}
              valueOfUseEffectArray={[filter]}
              children={
                <RouteWaiter
                  loading={loading}
                  error={errorText}
                  data={customisationData}
                  children={
                    <div>
                      {customisationData?.map((item, index) => (
                        <CardsComp
                          item={item}
                          key={index}
                          setLoading={setLoading}
                          setCustomisationData={setCustomisationData}
                          index={index}
                        />
                      ))}
                    </div>
                  }
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}
export const FlexDiv = ({ title, value }) => {
  return (
    <div className="flex flex-row items-center justify-between text-sm tracking-wide font-medium mb-2">
      <TextComp text={title} />
      <TextComp text={value} />
    </div>
  );
};

const CardsComp = ({ item, setLoading, setCustomisationData, index }) => {
  return (
    <div className="bg-white shadow my-10 py-5 px-5 cursor-pointer rounded">
      <UserDetails item={item} />
      <CustomisationDetails
        item={item}
        setLoading={setLoading}
        setCustomisationData={setCustomisationData}
        index={index}
      />
    </div>
  );
};

export default CustomisationRequest;
