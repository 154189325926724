import { errorToastify, successToastify } from "../../components/Toastify";
import { ErrorTexts } from "../../constants/ErrorTexts";
import { SuccessTexts } from "../../constants/SuccessTexts";
import { setBagCount } from "../Redux/BagData";
import { RoutesFetch } from "../RoutesFetch";

export const addItemToBag = ({
  productId,
  variantId,
  quantity,
  setLoading,
  dispatch,
  // bagData,
}) => {
  setLoading(true);

  RoutesFetch({
    method: "post",
    url: "/updateBagCollection",
    data: {
      productId,
      variantId,
      quantity,
    },
  })
    .then((res) => {
      if (res.msg === "fail") {
        errorToastify(ErrorTexts.sww);
      } else {
        // let newProduct = true;

        // for (let i = 0; i < bagData.length; i++) {
        //   if (
        //     bagData[i]?.productId === productId &&
        //     bagData[i]?.variantId === variantId
        //   ) {
        //     newProduct = false;

        //     break;
        //   }
        // }

        // if (newProduct === true) {
        //   dispatch(
        //     setBagData([
        //       ...bagData,
        //       {
        //         productId,
        //         variantId,
        //         quantity,
        //       },
        //     ])
        //   );
        // }

        RoutesFetch({
          method: "get",
          url: "/getBagItemLength",
        })
          .then((response) => {
            if (response.msg !== "fail") {
              dispatch(setBagCount(response.count));
            }
          })
          .catch((err) => {})
          .finally(() => {});

        successToastify(SuccessTexts.ProductAddedToBag);
      }
    })
    .catch(() => {
      errorToastify(ErrorTexts.sww);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const addItemToBagSingleCheckout = ({
  productId,
  variantId,
  quantity,
  setLoading,
  setShowSingleCheckoutDrawer,
}) => {
  setLoading(true);

  RoutesFetch({
    method: "post",
    url: "/updateSingleBagCollection",
    data: {
      productId,
      variantId,
      quantity,
    },
  })
    .then((res) => {
      if (res.msg === "fail") {
        errorToastify(ErrorTexts.sww);
      } else {
        setShowSingleCheckoutDrawer(true);

        // successToastify(SuccessTexts.ProductAddedToBag);
      }
    })
    .catch(() => {
      errorToastify(ErrorTexts.sww);
    })
    .finally(() => {
      setLoading(false);
    });
};
