import React from "react";
import CustomAccordion from "../../../../components/CustomAccordion";
import { FlexDiv } from "..";
import DisplayProducts from "../../../ProfileMenu/MyAccount/MyOrders/DisplayProducts";

function ProductDetails({ item }) {
  return (
    <div className="py-2">
      <CustomAccordion
        title={"Product Details"}
        details={
          <div>
            {/* <FlexDiv
            title={"Checkout Id"}
            value={item?.checkoutData?.checkoutId}
          /> */}

            <FlexDiv title={"Order Id"} value={item?.orderId} />

            {item?.bagData?.collectiveBagData?.map((productItem, index) => (
              <DisplayProducts
                key={index}
                productItem={productItem}
                orderData={item}
              />
            ))}

            {item?.bagData?.getYFreeProducts?.map((productItem, index) => (
              <DisplayProducts
                key={index}
                productItem={productItem}
                orderData={item}
                freeItem={true}
              />
            ))}
          </div>
        }
      />
    </div>
  );
}

export default ProductDetails;
