import { createSlice } from "@reduxjs/toolkit";

export const BagDataSlice = createSlice({
  name: "bagCountData",
  initialState: {
    bagCount: 0,
  },
  reducers: {
    setBagCount: (state, action) => {
      const data = action.payload;
      state.bagCount = data;
    },
  },
});

// this is for dispatch
export const { setBagCount } = BagDataSlice.actions;

// this is for configureStore
export default BagDataSlice.reducer;
