import React, { useState, useEffect } from "react";
import NavbarAndFooterComp from "../../../../components/NavbarAndFooterComp";

import { PageHeader } from "../../../../components/PageHeader";

import Loader from "../../../../components/Loader";
import OffersForm from "../OffersForm";
import { useLocation, useNavigate } from "react-router-dom";
import RouteWaiter from "../../../../components/RouteWaiter";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import { ErrorTexts } from "../../../../constants/ErrorTexts";

function CreateCoupon() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { state } = useLocation();
  const couponId = state?.couponId ? state?.couponId : null;
  const [couponData, setCouponData] = useState(null);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    getCouponDetails();
  }, []);

  const getCouponDetails = () => {
    RoutesFetch({
      method: "get",
      url: "/getCouponCollectionDetails",
      params: {
        couponId,
      },
    })
      .then((response) => {
        if (response.msg === "fail") {
          setErrorText(ErrorTexts.sww);
        } else {
          setCouponData(response?.couponData);
        }
      })
      .catch(() => {
        setErrorText(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <NavbarAndFooterComp
      children={
        <div>
          <RouteWaiter
            loading={loading}
            error={errorText}
            data={couponData}
            children={
              <>
                <PageHeader
                  text={couponData?.couponId ? "EDIT COUPON" : "CREATE COUPON"}
                />

                <div className="my-10 mx-5 md:mx-10">
                  <OffersForm
                    setLoading={setLoading}
                    couponData={couponData}
                    navigate={navigate}
                  />
                </div>
              </>
            }
          />

          <Loader loading={loading} />
        </div>
      }
    />
  );
}

export default CreateCoupon;
