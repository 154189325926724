export const orderStatusValues = {
  unfullfilled: "unfullfilled",
  fullfilled: "fullfilled",
};

export const orderStatus = [
  { label: "Unfullfilled", value: orderStatusValues?.unfullfilled },
  { label: "Fullfilled", value: orderStatusValues?.fullfilled },
];

export const orderStatusFilterValues = {
  unfullfilled: "unfullfilled",
  fullfilled: "fullfilled",
  all: "all",
};

export const orderStatusFilter = [
  { label: "All", value: orderStatusFilterValues?.all },
  { label: "Unfullfilled", value: orderStatusFilterValues?.unfullfilled },
  { label: "Fullfilled", value: orderStatusFilterValues?.fullfilled },
];
