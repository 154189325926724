import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Colors } from "../../constants/Colors";

function CustomRadioGroup({ title, value, setValue, data }) {
  return (
    <FormControl>
      <FormLabel
        id="demo-controlled-radio-buttons-group"
        sx={{
          color: Colors?.darkGray,
          "&.Mui-focused": {
            color: Colors?.darkGray,
          },
        }}
      >
        {title}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {data?.map((item, index) => (
          <FormControlLabel
            value={item?.value}
            control={
              <Radio
                sx={{
                  color: Colors.black,
                  "&.Mui-checked": {
                    color: Colors.black,
                  },
                }}
              />
            }
            label={item?.label}
            key={index}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default CustomRadioGroup;
