import React, { useEffect, useState } from "react";
import TextComp from "../../../components/TextComp";

import { FaParachuteBox } from "react-icons/fa6";

import Button from "../../../components/Button";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { errorToastify, infoToastify } from "../../../components/Toastify";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import { IoCloseOutline } from "react-icons/io5";

import {
  DiscountCalculation,
  RoundOffFloatIntoTwo,
} from "../../../config/PriceCalculation";
import { MdDelete } from "react-icons/md";
import { Colors } from "../../../constants/Colors";
import Skeleton from "@mui/material/Skeleton";
import SelectAddressModal from "../SelectAddressModal";
import CustomModal from "../../../components/Modal";
import RouteWaiter from "../../../components/RouteWaiter";
import DialogBox from "../../../components/DialogBox";
import { useDispatch, useSelector } from "react-redux";

import { RxCheck } from "react-icons/rx";
import { ImpValues } from "../../../constants/ImpValues";
import { InfoTexts } from "../../../constants/InfoTexts";
import MobileVerification from "../../../components/MobileVerification";
import { useNavigate } from "react-router-dom";
import { addItemInSavedList } from "../../../config/SavedItemsFunc";
import { setBagCount } from "../../../config/Redux/BagData";
import { IoChevronForward } from "react-icons/io5";
import SelectCouponsModal from "../SelectCouponsModal";
import { MdOutlineLocalOffer } from "react-icons/md";
import {
  checkPaymentStatusFunc,
  getAddress,
  proceedToCheckout,
} from "./CheckoutFunctions";
import InvalidCoupon from "../InvalidCoupon";
import OrderSuccessComp from "../OrderSuccessComp";
import { paymentMethodTypeValues } from "../../../constants/PaymentStatus";
import { v4 as uuidv4 } from "uuid";

function CheckoutCommonFlow({
  loading,
  bagData,
  setLoading,
  setBagData,
  setUpdateBagData,
  updateBagData,
  setCouponCode,
  couponCode,
  updateCouponCode,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData?.data);
  const bagCount = useSelector((state) => state?.bagCountData?.bagCount);

  const [addressLoading, setAddressLoading] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [openSelectAddressModal, setOpenSelectAddressModal] = useState(false);

  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [couponData, setCouponData] = useState(null);

  const [paymentType, setPaymentType] = useState(
    paymentMethodTypeValues?.online
  );

  const [orderId, setOrderId] = useState(null);

  const [showMobileVerificationModal, setShowMobileVerificationModal] =
    useState(false);

  const [orderSuccess, setOrderSuccess] = useState(false);
  const [checkPaymentStatus, setCheckPaymentStatus] = useState(false);

  useEffect(() => {
    getAddress({ setAddressLoading, setDeliveryAddress });
  }, []);

  useEffect(() => {
    setOrderId(uuidv4());
  }, []);

  useEffect(() => {
    if (checkPaymentStatus) {
      const interval = setInterval(() => {
        checkPaymentStatusFunc({
          orderId,
          deliveryAddress,
          bagData,
          setUpdateBagData,
          updateBagData,
          couponData,
          paymentType,
          dispatch,
          setOrderSuccess,
          setCouponCode,
          setCheckPaymentStatus,
        });
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [checkPaymentStatus]);

  const removeCoupon = ({ bagId, updateBagData }) => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/removeMappedCoupon",
      data: {
        bagId,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          if (updateBagData === true) {
            setUpdateBagData(updateBagData + 1);
          }
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (couponCode && bagData) {
      applyAndVerifyCoupon();
    } else {
      setLoading(false);
    }
  }, [couponCode, updateCouponCode]);

  const applyAndVerifyCoupon = () => {
    setLoading(true);
    RoutesFetch({
      method: "get",
      url: "/applyAndVerifyCoupon",
      params: {
        bagData,
        couponCode,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          setCouponCode("");
          setCouponData(null);
          errorToastify(ErrorTexts.sww);
        } else if (res.msg === "invalid") {
          setCouponCode("");
          setCouponData(null);
          setInvalidCoupon(true);
          removeCoupon({ bagId: bagData?.bagId });
        } else {
          setCouponData(res?.couponData);
          setBagData(res?.bagData);
        }
      })
      .catch((err) => {
        setCouponCode("");
        setCouponData(null);
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <RouteWaiter
        loading={loading}
        data={bagData}
        noDataChildren={
          <div className="flex flex-col items-center justify-center mt-28">
            <FaParachuteBox size={80} />

            <TextComp
              text={"Currently you don't have any product in your shopping bag"}
              customStyle={"text-sm font-medium tracking wide mt-2 mb-8"}
            />
            <Button btnText={"ADD PRODUCT"} />
          </div>
        }
        children={
          <div className="my-2">
            {bagData?.collectiveBagData?.map((item, index) => (
              <ProductsList
                item={item}
                bagData={bagData}
                setLoading={setLoading}
                key={index}
                navigate={navigate}
                userData={userData}
                dispatch={dispatch}
                updateBagData={updateBagData}
                setUpdateBagData={setUpdateBagData}
                bagCount={bagCount}
                couponCode={couponCode}
              />
            ))}

            {bagData?.getYFreeProducts?.map((item, index) => (
              <FreeProductList item={item} navigate={navigate} />
            ))}

            {addressLoading === true ? (
              <Skeleton variant="rectangular" height={60} />
            ) : (
              <div className="mt-7 mb-2 border border-lightGray bg-lightGray shadow rounded p-5 tracking-wider">
                <TextComp
                  text="DELIVERY ADDRESS"
                  customStyle={"font-semibold text-sm"}
                />
                {deliveryAddress === null ? null : (
                  <TextComp
                    text={`${deliveryAddress?.address1}, ${deliveryAddress?.address2}, ${deliveryAddress?.city}, ${deliveryAddress?.province}, ${deliveryAddress?.zip}`}
                    customStyle={"text-xs mt-4"}
                  />
                )}
                <div
                  onClick={() => {
                    setOpenSelectAddressModal(true);
                  }}
                >
                  <TextComp
                    text={"Change / Add delivery address"}
                    customStyle={
                      "text-xs mt-4 text-green underline cursor-pointer"
                    }
                  />
                </div>
              </div>
            )}

            <div className="mb-2 border border-lightGray bg-lightGray shadow rounded p-5 tracking-wider">
              <div className="">
                <div
                  onClick={() => {
                    setOpenCouponModal(true);
                  }}
                  className="text-xs p-4 tracking-widest font-semibold border border-black rounded bg-black cursor-pointer flex items-center justify-between"
                >
                  <TextComp text="APPLY COUPON" customStyle={"text-white"} />

                  <IoChevronForward size={15} color={Colors.white} />
                </div>

                {!couponCode ? null : (
                  <div className="flex flex-row items-center border border-black bg-white rounded p-4 mt-4 justify-between">
                    <TextComp text={couponCode} customStyle={"text-black"} />

                    <IoCloseOutline
                      color={Colors.black}
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        removeCoupon({
                          bagId: bagData?.bagId,
                          updateBagData: true,
                        });
                        // setUpdateBagData(updateBagData + 1);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="my-2 border border-lightGray bg-lightGray shadow rounded p-5 text-sm tracking-wider">
              <div className="flex items-center justify-between my-2">
                <TextComp text="Total MRP" />
                <TextComp text={`₹${bagData?.totalMrp}`} />
              </div>

              {bagData?.discountOnMrp > 0 && (
                <div className="flex items-center justify-between my-2">
                  <TextComp text="Discount on MRP" />
                  <TextComp
                    text={`- ₹${bagData?.discountOnMrp}`}
                    customStyle={"text-green"}
                  />
                </div>
              )}

              <div className="flex items-center justify-between my-2">
                <TextComp text="Coupon Discount" />

                {!bagData?.totalCouponDiscount ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenCouponModal(true);
                    }}
                  >
                    <TextComp
                      text="Apply coupon"
                      customStyle={"text-rosePink"}
                    />
                  </div>
                ) : (
                  <TextComp
                    text={`- ₹${bagData?.totalCouponDiscount}`}
                    customStyle={"text-green"}
                  />
                )}
              </div>

              <div className="flex items-center justify-between my-2">
                <TextComp text="Convenience Fee" />
                <TextComp text="Free" customStyle={"text-green"} />
              </div>

              <div className="flex items-center justify-between my-2 mt-4 font-semibold border-t pt-4 border-black">
                <TextComp text="Total Amount" />
                <TextComp text={`₹${bagData?.totalAmountToPaid}`} />
              </div>

              <div className="text-xs tracking-widest font-semibold border mt-5 border-black rounded bg-white cursor-pointer">
                <div className="border-b border-black">
                  {" "}
                  <div className="p-4" onClick={() => setPaymentType("online")}>
                    <div className="flex items-center justify-between">
                      <TextComp
                        text="PAY ONLINE via razorpay"
                        customStyle={""}
                      />
                      {paymentType === paymentMethodTypeValues?.online ? (
                        <RxCheck size={16} />
                      ) : null}
                    </div>
                    <TextComp
                      text="Get extra 5% off on prepaid orders"
                      customStyle={"text-darkGray font-light text-[10px] pt-1"}
                    />
                  </div>
                </div>

                <div
                  className="p-4"
                  onClick={() => {
                    bagData?.totalAmountToPaid > ImpValues.codAvailableLimit
                      ? infoToastify(InfoTexts.codNotAllowed)
                      : setPaymentType("cod");
                  }}
                >
                  <div className="flex items-center justify-between">
                    <TextComp text="PAY ON DELIVERY" customStyle={""} />
                    {paymentType === paymentMethodTypeValues?.cod ? (
                      <RxCheck size={16} />
                    ) : null}
                  </div>
                  <TextComp
                    text="Delivery charges are extra on cod"
                    customStyle={"text-darkGray font-light text-[10px] pt-1"}
                  />
                  <TextComp
                    text={`COD is available upto ₹${ImpValues.codAvailableLimit}`}
                    customStyle={"text-darkGray font-light text-[10px] pt-1"}
                  />
                </div>
              </div>

              <div className="mt-8">
                <Button
                  btnText={"PROCEED TO CHECKOUT"}
                  onPress={() =>
                    userData?.mobileNumberVerified === true
                      ? proceedToCheckout({
                          orderId,
                          setLoading,
                          deliveryAddress,
                          bagData,
                          setUpdateBagData,
                          updateBagData,
                          couponData,
                          userData,
                          paymentType,
                          dispatch,
                          setOrderSuccess,
                          setCouponCode,
                          setCheckPaymentStatus,
                        })
                      : setShowMobileVerificationModal(true)
                  }
                />
              </div>
            </div>
          </div>
        }
      />

      <CustomModal
        open={showMobileVerificationModal}
        setOpen={setShowMobileVerificationModal}
        children={
          <MobileVerification
            open={showMobileVerificationModal}
            setOpen={setShowMobileVerificationModal}
            setLoading={setLoading}
            loading={loading}
            setUpdateBagData={setUpdateBagData}
            updateBagData={updateBagData}
          />
        }
      />

      <CustomModal
        open={openCouponModal}
        setOpen={setOpenCouponModal}
        children={
          <SelectCouponsModal
            open={openCouponModal}
            setOpen={setOpenCouponModal}
            setLoading={setLoading}
            loading={loading}
            setCouponCode={setCouponCode}
            couponCode={couponCode}
            bagId={bagData?.bagId}
            setUpdateBagData={setUpdateBagData}
            updateBagData={updateBagData}
            removeCoupon={removeCoupon}
          />
        }
      />

      <CustomModal
        open={openSelectAddressModal}
        setOpen={setOpenSelectAddressModal}
        children={
          <SelectAddressModal
            open={openSelectAddressModal}
            setOpen={setOpenSelectAddressModal}
            setLoading={setLoading}
            deliveryAddress={deliveryAddress}
            setDeliveryAddress={setDeliveryAddress}
            loading={loading}
          />
        }
      />

      <CustomModal
        open={invalidCoupon}
        setOpen={setInvalidCoupon}
        atCenter={true}
        children={
          <InvalidCoupon
            setInvalidCoupon={setInvalidCoupon}
            setOpenCouponModal={setOpenCouponModal}
          />
        }
      />

      <CustomModal
        open={orderSuccess}
        setOpen={setOrderSuccess}
        atCenter={true}
        children={
          <OrderSuccessComp
            setOrderSuccess={setOrderSuccess}
            navigationRoute={"/myorders"}
            title="ORDER SUCCEEDED"
            details="Congratulations! Your order has been successfully placed."
            btnText="ORDERS"
          />
        }
      />
    </div>
  );
}

const ProductsList = ({
  item,
  bagData,
  setLoading,
  navigate,
  userData,
  dispatch,
  updateBagData,
  setUpdateBagData,
  bagCount,
  couponCode,
}) => {
  const quantity = parseInt(item?.quantity);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [moveProductDialog, setMoveProductDialog] = useState(false);

  // const [savedItem, setSavedItem] = useState(false);

  const moveFromBag = () => {
    addItemInSavedList({
      // setSavedItem,
      genieaseCustomerId: userData?.genieaseCustomerId,
      productId: item?.id,
      fromBag: true,
      removeFromBag,
      setMoveProductDialog,
      setLoading,
    });
  };

  const removeFromBag = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/deleteBagItemProduct",
      data: {
        bagId: bagData?.bagId,
        variantId: item?.variants?.id,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          setLoading(false);
          errorToastify(ErrorTexts.sww);
        } else {
          // for (let i = 0; i < bagData?.length; i++) {
          //   if (item?._id === bagData[i]?._id) {
          //     bagData.splice(i, 1);
          //     setBagDataFromParent([...bagData]);
          //     setDeleteProductDialog(false);
          //     dispatch(setBagData(bagData));
          //     setBagDataUpdate(bagDataUpdate + 1);
          //     break;
          //   }
          // }
          setDeleteProductDialog(false);
          setUpdateBagData(updateBagData + 1);
          dispatch(setBagCount(bagCount - 1));
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {});
  };

  const updateQuantity = ({ value }) => {
    setLoading(true);

    setTimeout(() => {
      RoutesFetch({
        method: "post",
        url: "/updateBagItemProductQuantity",
        data: {
          bagId: bagData?.bagId,
          variantId: item?.variants?.id,
          quantity: value,
        },
      })
        .then((res) => {
          if (res.msg === "fail") {
            setLoading(false);
            errorToastify(ErrorTexts.sww);
          } else {
            // for (let i = 0; i < bagData?.length; i++) {
            //   if (bagData[i]._id === item._id) {
            //     bagData[i].quantity = res?.quantity;
            //     setBagDataFromParent([...bagData]);

            //     break;
            //   }
            // }
            // setBagDataUpdate(bagDataUpdate + 1);
            // setQuantity(res?.quantity);
            setUpdateBagData(updateBagData + 1);
          }
        })
        .catch(() => {
          setLoading(false);
          errorToastify(ErrorTexts.sww);
        })
        .finally(() => {});
    }, 1000);
  };

  return (
    <div
      className="py-5 border-b-2 border-lightGray "
      onClick={() => {
        navigate(`/product/${item.id}`);
      }}
    >
      <div className="flex justify-between">
        <div className="flex items-center cursor-pointer">
          <img src={item?.image?.src} className="w-20" />
          <div className="mx-5">
            <TextComp
              text={item?.title?.toUpperCase()}
              customStyle="text-sm font-medium tracking-wider"
            />

            <div>
              {item?.variants?.title === "Default Title" ? null : (
                <TextComp
                  text={`Size: ${item?.variants?.title}`}
                  customStyle="text-xs mt-2 text-darkGray"
                />
              )}

              <div className="mt-2 w-fit bg-lightGray text-sm font-medium flex flex-row items-center shadow-sm rounded">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    quantity <= 1
                      ? setDeleteProductDialog(true)
                      : updateQuantity({ value: quantity - 1 });
                  }}
                >
                  <TextComp
                    customStyle="cursor-pointer text-xl px-2 text-sm border-r-2 border-white"
                    text={"-"}
                  />
                </div>
                <TextComp
                  customStyle="px-2 text-sm"
                  text={`Qty: ${quantity}`}
                />
                <div
                  onClick={(e) => {
                    e.stopPropagation();

                    item?.variants?.inventory_quantity <= quantity
                      ? infoToastify(
                          `Currently, the maximum order quantity available is ${item?.variants?.inventory_quantity} pieces.`
                        )
                      : updateQuantity({ value: quantity + 1 });
                  }}
                >
                  <TextComp
                    customStyle="cursor-pointer text-xl px-2 text-sm border-l-2 border-white"
                    text={"+"}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mt-2">
                {item?.couponDiscount ? (
                  <TextComp
                    text={`₹ ${RoundOffFloatIntoTwo(
                      item?.variants?.price * quantity - item?.couponDiscount
                    )}`}
                    customStyle={"text-sm font-medium pr-2"}
                  />
                ) : (
                  <TextComp
                    text={`₹ ${RoundOffFloatIntoTwo(
                      item?.variants?.price * quantity
                    )}`}
                    customStyle={"text-sm font-medium pr-2"}
                  />
                )}

                {item?.variants?.compare_at_price === "0" ||
                item?.variants?.compare_at_price === 0 ||
                !item?.variants?.compare_at_price ? null : (
                  <>
                    <TextComp
                      customStyle="pr-2 text-xs text-darkGray line-through"
                      text={`MRP ₹ ${RoundOffFloatIntoTwo(
                        item?.variants?.compare_at_price * quantity
                      )}`}
                    />

                    <TextComp
                      customStyle="pr-2 text-xs font-medium text-rosePink"
                      text={`(${DiscountCalculation(
                        Math.floor(item?.variants?.price * quantity),
                        Math.floor(item?.variants?.compare_at_price * quantity)
                      )} % OFF)`}
                    />
                  </>
                )}
              </div>

              {item?.couponDiscount ? (
                <div className="flex flex-row items-center rounded bg-green w-fit p-2 mt-2">
                  <MdOutlineLocalOffer color={Colors.white} />
                  <TextComp
                    customStyle="px-2 text-xs font-medium text-white"
                    text={`${couponCode}`}
                  />
                  <TextComp
                    customStyle="pr-2 text-xs font-medium text-white"
                    text={`- (₹${item?.couponDiscount})`}
                  />
                </div>
              ) : null}

              {item?.variants?.inventory_quantity === 0 ? (
                <TextComp
                  customStyle={"text-xs text-red mt-1 tracking-wide"}
                  text={`Currently, this product is out of stock.`}
                />
              ) : item?.variants?.inventory_quantity < item?.quantity ? (
                <TextComp
                  customStyle={"text-xs text-red mt-1 tracking-wide"}
                  text={`Currently, the maximum order quantity available is ${item?.variants?.inventory_quantity} pieces.`}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteProductDialog(true);
          }}
        >
          <MdDelete size={20} color={Colors.darkGray} />
        </div>
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation();
          setMoveProductDialog(true);
        }}
      >
        <TextComp
          customStyle="cursor-pointer text-xs underline tracking-wide text-rosePink text-right"
          text={"Move to wishlist"}
        />
      </div>

      <DialogBox
        open={deleteProductDialog}
        setOpen={setDeleteProductDialog}
        title={"Are you sure you'd like to remove this product from your bag?"}
        agreeFunc={(e) => {
          e.stopPropagation();
          removeFromBag();
        }}
      />

      <DialogBox
        open={moveProductDialog}
        setOpen={setMoveProductDialog}
        title={"Are you sure you'd like to move this product to wishlist?"}
        agreeFunc={(e) => {
          e.stopPropagation();
          moveFromBag();
        }}
      />
    </div>
  );
};

const FreeProductList = ({ item, navigate }) => {
  return (
    <div
      className="py-5 border-b-2 border-lightGray bg-lightGray"
      onClick={() => {
        navigate(`/product/${item.id}`);
      }}
    >
      <div className="flex justify-between">
        <div className="flex items-center cursor-pointer">
          <img src={item?.image?.src} className="w-20" />
          <div className="mx-5">
            <TextComp
              text={item?.title?.toUpperCase()}
              customStyle="text-sm font-medium tracking-wider"
            />

            {item?.variants && item?.variants?.title !== "Default Title" ? (
              <div>
                <TextComp
                  text={`Size: ${item?.variants?.title}`}
                  customStyle="text-xs mt-2 text-darkGray"
                />
              </div>
            ) : null}

            <TextComp
              text={"Free"}
              customStyle={"text-sm text-green font-medium mt-2 pr-2"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCommonFlow;
