import { RoutesFetch } from "../RoutesFetch";

import { setData } from "../Redux/UserData";
import { errorToastify, successToastify } from "../../components/Toastify";
import { ErrorTexts } from "../../constants/ErrorTexts";
import { setBagCount } from "../Redux/BagData";
import { VerifyTokenFunc } from "../VerifyTokenFunc";

export const logoutFunc = ({
  navigate,
  pathname,
  dispatch,
  setLoading,
  setProfileMenu,
  setShowMenuDrawer,
  cookies,
  setShowAccountDrawer,
}) => {
  setLoading(true);

  setTimeout(() => {
    RoutesFetch({ method: "get", url: "/userLogout" })
      .then(async (response) => {
        if (response.msg === "success") {
          setShowMenuDrawer !== undefined
            ? setShowMenuDrawer(false)
            : setShowAccountDrawer !== undefined
            ? setShowAccountDrawer(false)
            : setProfileMenu(null);

          await dispatch(setData({}));
          await dispatch(setBagCount(0));
          await navigate(pathname, { replace: true });

          VerifyTokenFunc({
            setLoading,
            dispatch,
            cookies,
          });
        } else {
          errorToastify(ErrorTexts.sww);
        }
      })
      .catch((err) => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 1000);
};
