import React, { useState } from "react";
import CustomAccordion from "../../../../components/CustomAccordion";
import TextComp from "../../../../components/TextComp";
import { FlexDiv } from "..";

import SelectInput from "../../../../components/TextInput/SelectInput";
import DialogBox from "../../../../components/DialogBox";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import Button from "../../../../components/Button";
import {
  orderStatus,
  orderStatusValues,
} from "../../../../constants/OrderStatus";

function OrderDetails({ item, setLoading, setOrderData, index }) {
  const [updateOrderStatus, setUpdateOrderStatus] = useState(item?.orderStatus);

  const [updateOrderStatusDialogBox, setUpdateOrderStatusDialogBox] =
    useState(false);

  const updateOrderStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updateOrderStatusCollection",
      data: {
        orderId: item?.orderId,
        orderStatus: updateOrderStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setOrderData((prevOrderData) =>
            prevOrderData.map((order, i) =>
              i === index
                ? {
                    ...order,
                    orderStatus: updateOrderStatus,
                  }
                : order
            )
          );

          successToastify(SuccessTexts.orderStatusUpdated);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdateOrderStatusDialogBox(false);
        setLoading(false);
      });
  };

  return (
    <div className="py-2">
      <CustomAccordion
        title={"Order Details"}
        details={
          <div>
            <FlexDiv title={"Order Status"} value={item?.orderStatus} />

            {item?.orderStatus !== orderStatusValues?.fullfilled ? (
              <div className="my-5">
                <SelectInput
                  label={"Update order status"}
                  values={orderStatus}
                  id="demo-controlled-open-select"
                  onChange={(e) => setUpdateOrderStatus(e.target.value)}
                  defaultValue={updateOrderStatus}
                />
                <Button
                  btnText={"UPDATE ORDER STATUS"}
                  customStyle={
                    item?.orderStatus === updateOrderStatus
                      ? "my-2 bg-darkGray border-darkGray"
                      : "my-2"
                  }
                  onPress={() =>
                    item?.orderStatus === updateOrderStatus
                      ? null
                      : setUpdateOrderStatusDialogBox(true)
                  }
                />
              </div>
            ) : null}
          </div>
        }
      />

      <DialogBox
        title={"Kindly ensure the order is fulfilled before proceeding."}
        open={updateOrderStatusDialogBox}
        setOpen={setUpdateOrderStatusDialogBox}
        agreeFunc={updateOrderStatusFunc}
      />
    </div>
  );
}

export default OrderDetails;
