import React, { useState } from "react";
import NavbarAndFooterComp from "../../../../components/NavbarAndFooterComp";
import { PageHeader } from "../../../../components/PageHeader";
import InfiniteScrollComp from "../../../../components/InfiniteScrollComp";
import RouteWaiter from "../../../../components/RouteWaiter";
import { FaBox, FaArrowRight } from "react-icons/fa";
import { Colors } from "../../../../constants/Colors";
import TextComp from "../../../../components/TextComp";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { returnCancelStatusValues } from "../../../../constants/ReturnCancelStatus";

function MyOrders() {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  const navigate = useNavigate();
  return (
    <NavbarAndFooterComp
      children={
        <div>
          <PageHeader text={"MY ORDERS"} />

          <InfiniteScrollComp
            url="/getUserOrderCollection"
            responseDataName="orderData"
            setLoading={setLoading}
            setErrorText={setErrorText}
            data={orderData}
            setData={setOrderData}
            children={
              <RouteWaiter
                loading={loading}
                error={errorText}
                data={orderData}
                children={
                  <div>
                    {orderData?.map((item, index) => (
                      <CardsComp item={item} key={index} navigate={navigate} />
                    ))}
                  </div>
                }
              />
            }
          />
        </div>
      }
    />
  );
}

const CardsComp = ({ item, navigate }) => {
  const productItem = item?.bagData?.collectiveBagData[0];
  return (
    <div
      className="bg-white shadow m-4 p-4 md:m-10 md:p-10 rounded cursor-pointer"
      onClick={() => {
        navigate(`/orderdetails/${item.orderId}`);
      }}
    >
      <div className="flex items-center">
        <div className="rounded-full bg-black p-3">
          <FaBox color={Colors.white} size={20} />
        </div>
        <div>
          <TextComp
            text={
              item?.cancellationData?.cancellationStatus ===
              returnCancelStatusValues?.approved
                ? "CANCELLED"
                : item?.returnData?.returnStatus ===
                  returnCancelStatusValues?.approved
                ? "RETURNED"
                : item?.deliveryData?.deliveryStatus?.toUpperCase()
            }
            customStyle={"mx-4 text-sm font-medium tracking-widest"}
          />
          <TextComp
            text={`Last updated on ${moment(item?.updatedAt).format(
              "DD-MM-YYYY hh:mm"
            )}`}
            customStyle={"mx-4 text-[9px] font-light text-darkGray"}
          />
        </div>
      </div>

      <div className="mt-5">
        <div className="flex items-center">
          <img src={productItem?.image?.src} className="w-20 mr-5" />
          <div>
            <TextComp
              text={productItem?.title}
              customStyle={"text-[16px] text-black"}
            />
            <TextComp
              text={`qty: ${productItem?.quantity}`}
              customStyle={"text-[14px] text-black"}
            />
            {productItem?.variants?.title === "Default Title" ? null : (
              <TextComp
                text={`size: ${productItem?.variants?.title}`}
                customStyle={"text-[14px] text-black"}
              />
            )}
          </div>
        </div>

        <div className="mt-5 flex items-end justify-end">
          <div className="flex items-center">
            <TextComp
              text={"VIEW ALL"}
              customStyle={"mx-1 text-xs font-semibold text-black"}
            />
            <FaArrowRight size={14} color={Colors.black} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
