import React from "react";

import SelectInput from "../../../../components/TextInput/SelectInput";

import { OutlineTextInput } from "../../../../components/TextInput";
import { useFormik } from "formik";
import Button from "../../../../components/Button";

import * as Yup from "yup";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import TextComp from "../../../../components/TextComp";
import {
  BooleanTypes,
  BooleanTypesValues,
  CouponTypes,
  CouponTypesValues,
  CustomerSpecificTypes,
  CustomerSpecificTypesValues,
  ProductCollectionSpecificTypes,
  ProductCollectionSpecificTypesValues,
  minimumRequirementTypes,
  minimumRequirementTypesValues,
  rewardGivenASTypes,
  rewardGivenASTypesValues,
} from "../../../../constants/CouponTypes";

import dayjs from "dayjs";

import DateInput from "../../../../components/TextInput/DateInput";
import { FormHelperText } from "@mui/material";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

function OffersForm({ setLoading, couponData, navigate }) {
  const formik = useFormik({
    initialValues: {
      couponName: couponData?.couponName || "",
      couponCode: couponData?.couponCode || "",
      couponDescription: couponData?.couponDescription || "",
      startDate: dayjs(couponData?.startDate, "DD-MM-YYYY") || null,
      endDate: dayjs(couponData?.endDate, "DD-MM-YYYY") || null,
      couponType: couponData?.couponType || "",
      usageLimit: couponData?.usageLimit || "",
      couponActive: couponData?.couponActive || BooleanTypesValues.false,
      showInList: couponData?.showInList || BooleanTypesValues.false,

      conditionProductCollectionSpecificType:
        couponData?.conditionProductCollectionSpecificType || "",
      conditionProductCollectionSpecificValue:
        couponData?.conditionProductCollectionSpecificValue || "",
      conditionCustomerSpecificType:
        couponData?.conditionCustomerSpecificType || "",
      conditionCustomerSpecificValue:
        couponData?.conditionCustomerSpecificValue || "",
      conditionMinimumRequirementType:
        couponData?.conditionMinimumRequirementType || "",
      minimumQuantity: couponData.minimumQuantity || "",
      minimumBagAmount: couponData.minimumBagAmount || "",

      rewardProductCollectionSpecificType:
        couponData?.rewardProductCollectionSpecificType || "",
      rewardProductCollectionSpecificValue:
        couponData?.rewardProductCollectionSpecificValue || "",
      rewardQuantity: couponData?.rewardQuantity || "",
      couponValue: couponData?.couponValue || "",
      flatProductAmount: couponData?.flatProductAmount || "",
      rewardGivenType: couponData?.rewardGivenType || "",
      rewardGivenValue: couponData?.rewardGivenValue || "",
    },

    validationSchema: Yup.object().shape({
      couponName: Yup.string().required("Please enter a coupon name"),
      couponCode: Yup.string().required("Please enter a coupon code"),
      startDate: Yup.string().required("Please enter a start date"),
      endDate: Yup.string().required("Please enter a end date"),
      couponType: Yup.string().required("Please select a type of coupon"),
      couponActive: Yup.boolean().required(
        "Please select coupon is active or not"
      ),
      showInList: Yup.boolean().required(
        "Please select coupon will show or not"
      ),

      ////////////////
      conditionProductCollectionSpecificType: Yup.string().required(
        "Please select coupon applicable products"
      ),
      conditionProductCollectionSpecificValue: Yup.string().when(
        "conditionProductCollectionSpecificType",
        {
          is: (i) => i !== ProductCollectionSpecificTypesValues.all_products,
          then: () => Yup.string().required("Please enter product ids"),
        }
      ),
      conditionCustomerSpecificType: Yup.string().required(
        "Please select coupon applicable customers"
      ),
      conditionCustomerSpecificValue: Yup.string().when(
        "conditionCustomerSpecificType",
        {
          is: (i) => i !== CustomerSpecificTypesValues.all_customers,
          then: () => Yup.string().required("Please enter customer ids"),
        }
      ),
      conditionMinimumRequirementType: Yup.string().required(
        "Please select coupon applicable requirement"
      ),

      minimumQuantity: Yup.string().when("conditionMinimumRequirementType", {
        is: (i) => i === minimumRequirementTypesValues.minimum_quantity_bag,
        then: () => Yup.string().required("Please enter customer ids"),
      }),

      minimumBagAmount: Yup.string().when("conditionMinimumRequirementType", {
        is: (i) => i === minimumRequirementTypesValues.minimum_amount_bag,
        then: () => Yup.string().required("Please enter customer ids"),
      }),

      ///////////////
      rewardProductCollectionSpecificType: Yup.string().required(
        "Please select reward applicable products"
      ),
      rewardProductCollectionSpecificValue: Yup.string().when(
        "rewardProductCollectionSpecificType",
        {
          is: (i) => i !== ProductCollectionSpecificTypesValues.all_products,
          then: () => Yup.string().required("Please enter product ids"),
        }
      ),

      couponValue: Yup.string().when("couponType", {
        is: (i) =>
          i === CouponTypesValues.fixed || i === CouponTypesValues.percentage,
        then: () => Yup.string().required("Please enter coupon value"),
      }),

      flatProductAmount: Yup.string().when("couponType", {
        is: (i) => i === CouponTypesValues.flat,
        then: () =>
          Yup.string().required("Please enter flat amount of product"),
      }),

      rewardQuantity: Yup.string().when("couponType", {
        is: (i) => i === CouponTypesValues.buy_x_get_y,
        then: () => Yup.string().required("Please enter reward quantity"),
      }),

      rewardGivenType: Yup.string().required("Please select reward given type"),

      rewardGivenValue: Yup.string().when("rewardGivenType", {
        is: (i) => i !== rewardGivenASTypesValues.free,
        then: () => Yup.string().required("Please enter reward given value"),
      }),
    }),

    onSubmit: (values) => {
      setLoading(true);

      RoutesFetch({
        method: "post",
        url: "/updateCouponCollection",
        data: {
          couponId: couponData?.couponId || null,
          couponName: values.couponName,
          couponCode: values.couponCode,
          couponDescription: values.couponDescription,
          startDate: dayjs(values.startDate).format("DD-MM-YYYY"),
          endDate: dayjs(values.endDate).format("DD-MM-YYYY"),
          couponType: values.couponType,
          usageLimit: values.usageLimit,
          couponActive: values.couponActive,
          showInList: values.showInList,

          conditionProductCollectionSpecificType:
            values.conditionProductCollectionSpecificType,
          conditionProductCollectionSpecificValue:
            values.conditionProductCollectionSpecificValue,
          conditionCustomerSpecificType: values.conditionCustomerSpecificType,
          conditionCustomerSpecificValue: values.conditionCustomerSpecificValue,
          conditionMinimumRequirementType:
            values.conditionMinimumRequirementType,
          minimumQuantity: values.minimumQuantity,
          minimumBagAmount: values.minimumBagAmount,

          rewardProductCollectionSpecificType:
            values.rewardProductCollectionSpecificType,
          rewardProductCollectionSpecificValue:
            values.rewardProductCollectionSpecificValue,
          rewardQuantity: values.rewardQuantity,
          rewardGivenType: values.rewardGivenType,
          couponValue: values.couponValue,
          rewardGivenValue: values.rewardGivenValue,
          flatProductAmount: values.flatProductAmount,
        },
      })
        .then((response) => {
          if (response.msg === "fail") {
            errorToastify(ErrorTexts.sww);
          } else {
            navigate(-1);
            successToastify(SuccessTexts.couponCreated);
          }
        })
        .catch((err) => {
          errorToastify(ErrorTexts.sww);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="my-10">
        <PartitionInputs
          childern1={
            <OutlineTextInput
              label={"Coupon Name"}
              id="couponName"
              value={formik.values.couponName}
              onChange={formik.handleChange}
              error={
                formik.touched.couponName && Boolean(formik.errors.couponName)
              }
              helperText={formik.touched.couponName && formik.errors.couponName}
            />
          }
          children2={
            <OutlineTextInput
              label={"Coupon Code"}
              id="couponCode"
              value={formik.values.couponCode}
              onChange={formik.handleChange}
              error={
                formik.touched.couponCode && Boolean(formik.errors.couponCode)
              }
              helperText={formik.touched.couponCode && formik.errors.couponCode}
            />
          }
        />

        <div className="w-full mb-5">
          <SelectInput
            label={"Coupon Type"}
            values={CouponTypes}
            id="couponType"
            value={formik.values.couponType}
            onChange={formik.handleChange("couponType")}
            defaultValue={formik.values.couponType}
            error={
              formik.touched.couponType && Boolean(formik.errors.couponType)
            }
            formikError={
              formik.touched.couponType &&
              formik.errors.couponType && (
                <FormHelperText>{formik.errors.couponType}</FormHelperText>
              )
            }
          />
        </div>

        <PartitionInputs
          childern1={
            <DateInput
              label={"Start Date"}
              id="startDate"
              value={formik.values.startDate}
              onChange={(value) => formik.setFieldValue("startDate", value)}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
            />
          }
          children2={
            <DateInput
              label={"End Date"}
              id="endDate"
              value={formik.values.endDate}
              onChange={(value) => formik.setFieldValue("endDate", value)}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
            />
          }
        />

        <PartitionInputs
          childern1={
            <OutlineTextInput
              label={"Usage Limit"}
              id="usageLimit"
              value={formik.values.usageLimit}
              onChange={formik.handleChange}
              error={
                formik.touched.usageLimit && Boolean(formik.errors.usageLimit)
              }
              helperText={formik.touched.usageLimit && formik.errors.usageLimit}
            />
          }
          children2={
            <SelectInput
              label={"Coupon Active"}
              values={BooleanTypes}
              id="couponActive"
              value={formik.values.couponActive}
              defaultValue={formik.values.couponActive}
              onChange={formik.handleChange("couponActive")}
              error={
                formik.touched.couponActive &&
                Boolean(formik.errors.couponActive)
              }
              formikError={
                formik.touched.couponActive &&
                formik.errors.couponActive && (
                  <FormHelperText>{formik.errors.couponActive}</FormHelperText>
                )
              }
            />
          }
        />

        <div className="w-full mb-5">
          <SelectInput
            label={"Show in List"}
            values={BooleanTypes}
            id="showInList"
            value={formik.values.showInList}
            defaultValue={formik.values.showInList}
            onChange={formik.handleChange("showInList")}
            error={
              formik.touched.showInList && Boolean(formik.errors.showInList)
            }
            formikError={
              formik.touched.showInList &&
              formik.errors.showInList && (
                <FormHelperText>{formik.errors.showInList}</FormHelperText>
              )
            }
          />
        </div>

        <div className="w-full mb-5">
          <OutlineTextInput
            label={"Coupon Description"}
            id="couponDescription"
            value={formik.values.couponDescription}
            onChange={formik.handleChange}
            multiline
            minRows={4}
            maxRows={4}
            error={
              formik.touched.couponDescription &&
              Boolean(formik.errors.couponDescription)
            }
            helperText={
              formik.touched.couponDescription &&
              formik.errors.couponDescription
            }
          />
        </div>

        {/* ///////////////////////////////////// */}

        <div>
          <TextComp
            text={"Apply Conditions"}
            customStyle={"text-lg font-medium mb-5"}
          />

          <PartitionInputs
            childern1={
              <SelectInput
                label={"Product/Collection Type"}
                values={ProductCollectionSpecificTypes}
                id="conditionProductCollectionSpecificType"
                value={formik.values.conditionProductCollectionSpecificType}
                defaultValue={
                  formik.values.conditionProductCollectionSpecificType
                }
                onChange={formik.handleChange(
                  "conditionProductCollectionSpecificType"
                )}
                error={
                  formik.touched.conditionProductCollectionSpecificType &&
                  Boolean(formik.errors.conditionProductCollectionSpecificType)
                }
                formikError={
                  formik.touched.conditionProductCollectionSpecificType &&
                  formik.errors.conditionProductCollectionSpecificType && (
                    <FormHelperText>
                      {formik.errors.conditionProductCollectionSpecificType}
                    </FormHelperText>
                  )
                }
              />
            }
            children2={
              <OutlineTextInput
                label={"Product/Collection Ids"}
                id="conditionProductCollectionSpecificValue"
                value={formik.values.conditionProductCollectionSpecificValue}
                onChange={formik.handleChange}
                error={
                  formik.touched.conditionProductCollectionSpecificValue &&
                  Boolean(formik.errors.conditionProductCollectionSpecificValue)
                }
                helperText={
                  formik.touched.conditionProductCollectionSpecificValue &&
                  formik.errors.conditionProductCollectionSpecificValue
                }
              />
            }
          />

          <PartitionInputs
            childern1={
              <SelectInput
                label={"Applicable Customer"}
                values={CustomerSpecificTypes}
                id="conditionCustomerSpecificType"
                value={formik.values.conditionCustomerSpecificType}
                defaultValue={formik.values.conditionCustomerSpecificType}
                onChange={formik.handleChange("conditionCustomerSpecificType")}
                error={
                  formik.touched.conditionCustomerSpecificType &&
                  Boolean(formik.errors.conditionCustomerSpecificType)
                }
                formikError={
                  formik.touched.conditionCustomerSpecificType &&
                  formik.errors.conditionCustomerSpecificType && (
                    <FormHelperText>
                      {formik.errors.conditionCustomerSpecificType}
                    </FormHelperText>
                  )
                }
              />
            }
            children2={
              <OutlineTextInput
                label={"Customer Ids"}
                id="conditionCustomerSpecificValue"
                value={formik.values.conditionCustomerSpecificValue}
                onChange={formik.handleChange}
                error={
                  formik.touched.conditionCustomerSpecificValue &&
                  Boolean(formik.errors.conditionCustomerSpecificValue)
                }
                helperText={
                  formik.touched.conditionCustomerSpecificValue &&
                  formik.errors.conditionCustomerSpecificValue
                }
              />
            }
          />

          <div className="w-full mb-5">
            <SelectInput
              label={"Minimum Requirement Type"}
              values={minimumRequirementTypes}
              id="conditionMinimumRequirementType"
              value={formik.values.conditionMinimumRequirementType}
              defaultValue={formik.values.conditionMinimumRequirementType}
              onChange={formik.handleChange("conditionMinimumRequirementType")}
              error={
                formik.touched.conditionMinimumRequirementType &&
                Boolean(formik.errors.conditionMinimumRequirementType)
              }
              formikError={
                formik.touched.conditionMinimumRequirementType &&
                formik.errors.conditionMinimumRequirementType && (
                  <FormHelperText>
                    {formik.errors.conditionMinimumRequirementType}
                  </FormHelperText>
                )
              }
            />
          </div>

          <PartitionInputs
            childern1={
              <OutlineTextInput
                label={"Minimum Amount In Bag"}
                id="minimumBagAmount"
                value={formik.values.minimumBagAmount}
                onChange={formik.handleChange}
                error={
                  formik.touched.minimumBagAmount &&
                  Boolean(formik.errors.minimumBagAmount)
                }
                helperText={
                  formik.touched.minimumBagAmount &&
                  formik.errors.minimumBagAmount
                }
              />
            }
            children2={
              <OutlineTextInput
                label={"Minimum Quantity In Bag"}
                id="minimumQuantity"
                value={formik.values.minimumQuantity}
                onChange={formik.handleChange}
                error={
                  formik.touched.minimumQuantity &&
                  Boolean(formik.errors.minimumQuantity)
                }
                helperText={
                  formik.touched.minimumQuantity &&
                  formik.errors.minimumQuantity
                }
              />
            }
          />
        </div>

        {/* ///////////////////////////////////// */}

        <div>
          <TextComp
            text={"Customer Gets"}
            customStyle={"text-lg font-medium mb-5"}
          />

          <PartitionInputs
            childern1={
              <SelectInput
                label={"Product/Collection Type"}
                values={ProductCollectionSpecificTypes}
                id="rewardProductCollectionSpecificType"
                value={formik.values.rewardProductCollectionSpecificType}
                defaultValue={formik.values.rewardProductCollectionSpecificType}
                onChange={formik.handleChange(
                  "rewardProductCollectionSpecificType"
                )}
                error={
                  formik.touched.rewardProductCollectionSpecificType &&
                  Boolean(formik.errors.rewardProductCollectionSpecificType)
                }
                formikError={
                  formik.touched.rewardProductCollectionSpecificType &&
                  formik.errors.rewardProductCollectionSpecificType && (
                    <FormHelperText>
                      {formik.errors.rewardProductCollectionSpecificType}
                    </FormHelperText>
                  )
                }
              />
            }
            children2={
              <OutlineTextInput
                label={"Product/Collection Ids"}
                id="rewardProductCollectionSpecificValue"
                value={formik.values.rewardProductCollectionSpecificValue}
                onChange={formik.handleChange}
                error={
                  formik.touched.rewardProductCollectionSpecificValue &&
                  Boolean(formik.errors.rewardProductCollectionSpecificValue)
                }
                helperText={
                  formik.touched.rewardProductCollectionSpecificValue &&
                  formik.errors.rewardProductCollectionSpecificValue
                }
              />
            }
          />

          <PartitionInputs
            childern1={
              <OutlineTextInput
                label={"Coupon value"}
                id="couponValue"
                value={formik.values.couponValue}
                onChange={formik.handleChange}
                error={
                  formik.touched.couponValue &&
                  Boolean(formik.errors.couponValue)
                }
                helperText={
                  formik.touched.couponValue && formik.errors.couponValue
                }
              />
            }
            children2={
              <OutlineTextInput
                label={"Flat Product Amount"}
                id="flatProductAmount"
                value={formik.values.flatProductAmount}
                onChange={formik.handleChange}
                error={
                  formik.touched.flatProductAmount &&
                  Boolean(formik.errors.flatProductAmount)
                }
                helperText={
                  formik.touched.flatProductAmount &&
                  formik.errors.flatProductAmount
                }
              />
            }
          />

          <PartitionInputs
            childern1={
              <SelectInput
                label={"Reward Given As"}
                values={rewardGivenASTypes}
                id="rewardGivenType"
                value={formik.values.rewardGivenType}
                defaultValue={formik.values.rewardGivenType}
                onChange={formik.handleChange("rewardGivenType")}
                error={
                  formik.touched.rewardGivenType &&
                  Boolean(formik.errors.rewardGivenType)
                }
                formikError={
                  formik.touched.rewardGivenType &&
                  formik.errors.rewardGivenType && (
                    <FormHelperText>
                      {formik.errors.rewardGivenType}
                    </FormHelperText>
                  )
                }
              />
            }
            children2={
              <OutlineTextInput
                label={"Reward Given Value"}
                id="rewardGivenValue"
                value={formik.values.rewardGivenValue}
                onChange={formik.handleChange}
                error={
                  formik.touched.rewardGivenValue &&
                  Boolean(formik.errors.rewardGivenValue)
                }
                helperText={
                  formik.touched.rewardGivenValue &&
                  formik.errors.rewardGivenValue
                }
              />
            }
          />

          <div className="w-full mb-5">
            <OutlineTextInput
              label={"Reward Quantity"}
              id="rewardQuantity"
              value={formik.values.rewardQuantity}
              onChange={formik.handleChange}
              error={
                formik.touched.rewardQuantity &&
                Boolean(formik.errors.rewardQuantity)
              }
              helperText={
                formik.touched.rewardQuantity && formik.errors.rewardQuantity
              }
            />
          </div>
        </div>
      </div>

      <button className="w-full" type="submit">
        <Button
          btnText={couponData?.couponId ? "Edit Coupon" : "Create Coupon"}
        />
      </button>
    </form>
  );
}

const PartitionInputs = ({ childern1, children2 }) => {
  return (
    <div className="mb-5 flex flex-row items-center w-full">
      <div className="mr-5 w-full">{childern1}</div>

      <div className="ml-5 w-full">{children2}</div>
    </div>
  );
};

export default OffersForm;
