import React from "react";
import NavbarAndFooterComp from "../../components/NavbarAndFooterComp";
import { PageHeader } from "../../components/PageHeader";
import TextComp from "../../components/TextComp";

import { useNavigate } from "react-router-dom";

function AdminPanel() {
  const navigate = useNavigate();

  const CardsComp = ({ heading, text, link }) => {
    return (
      <div
        onClick={() => navigate(link)}
        className="col-span-1 bg-white shadow my-10 mx-10 md:my-5 md:mx-5 lg:mx-10 py-10 px-5 flex flex-col items-center cursor-pointer rounded"
      >
        <TextComp
          text={heading}
          customStyle={"text-sm font-medium border-b w-fit"}
        />
        <TextComp text={text} customStyle={"text-xs text-darkGray pt-4"} />
      </div>
    );
  };

  return (
    <NavbarAndFooterComp
      children={
        <div className="flex flex-col items-center">
          <PageHeader text={`WELCOME ADMIN`} />

          <div className="md:grid grid-cols-2 w-full">
            <CardsComp
              heading={"All ORDERS"}
              text={"Manage all order and their status."}
              link={"/AllOrders"}
            />
            <CardsComp
              heading={"Customisation Requests"}
              text={"Manage all the customisation orders."}
              link={"/customisationrequest"}
            />
            <CardsComp
              heading={"OFFERS"}
              text={"Update all offers and their validity."}
              link={"/adminOffers"}
            />
          </div>
        </div>
      }
    />
  );
}

export default AdminPanel;
