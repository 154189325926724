export const cancelReasons = [
  { label: "Incorrect size ordered", value: "Incorrect size ordered" },
  {
    label: "Product not required anymore",
    value: "Product not required anymore",
  },
  {
    label: "Ordered by mistake",
    value: "Ordered by mistake",
  },
  {
    label: "Need to change delivery address",
    value: "Need to change delivery address",
  },
  {
    label: "Better price avaiable",
    value: "Better price avaiable",
  },
  { label: "Any other Reason", value: "Any other Reason" },
];

export const returnReasons = [
  { label: "Accidental order", value: "Accidental order" },
  { label: "Better price avaiable", value: "Better price avaiable" },
  {
    label: "Missing parts or accessories",
    value: "Missing parts or accessories",
  },
  {
    label: "Different from what was ordered",
    value: "Different from what was ordered",
  },
  {
    label: "Product not required anymore",
    value: "Product not required anymore",
  },
  { label: "Incorrect size ordered", value: "Incorrect size ordered" },
  { label: "Ordered by mistake", value: "Ordered by mistake" },
  { label: "Any other Reason", value: "Any other Reason" },
];
