import React, { useState } from "react";
import DialogBox from "../../../../components/DialogBox";
import CustomAccordion from "../../../../components/CustomAccordion";
import { FlexDiv } from "..";
import {
  CustomisationStatuses,
  CustomisationStatusesValues,
} from "../../../../constants/DeliveryStatuses";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import Button from "../../../../components/Button";
import SelectInput from "../../../../components/TextInput/SelectInput";

function CustomisationDetails({
  item,
  setLoading,
  setCustomisationData,
  index,
}) {
  const [updateCustomisationStatus, setUpdateCustomisationStatus] = useState(
    item?.status
  );
  const [
    updateCustomisationStatusDialogBox,
    setUpdateCustomisationStatusDialogBox,
  ] = useState(false);

  const updateCustomisationStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updateCustomisationStatus",
      data: {
        customisationId: item?._id,
        customisationStatus: updateCustomisationStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setCustomisationData((prevCustomisationData) =>
            prevCustomisationData.map((customisation, i) =>
              i === index
                ? {
                    ...customisation,
                    status: updateCustomisationStatus,
                  }
                : customisation
            )
          );

          successToastify(SuccessTexts.customisationStatusUpdated);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdateCustomisationStatusDialogBox(false);
        setLoading(false);
      });
  };

  return (
    <div className="py-2">
      <CustomAccordion
        title={"Customisation Details"}
        details={
          <div>
            <FlexDiv title={"Product Title"} value={`${item?.productTitle}`} />
            <FlexDiv
              title={"Design Description"}
              value={`${item?.designDescription}`}
            />
            <FlexDiv title={"Delivery Status"} value={`${item?.status}`} />

            {item?.status !== CustomisationStatusesValues?.delivered ? (
              <div className="my-5">
                <SelectInput
                  label={"Update customisation status"}
                  values={CustomisationStatuses}
                  id="demo-controlled-open-select"
                  onChange={(e) => setUpdateCustomisationStatus(e.target.value)}
                  defaultValue={updateCustomisationStatus}
                />
                <Button
                  btnText={"UPDATE CUSTOMISATION STATUS"}
                  customStyle={
                    item?.status === updateCustomisationStatus
                      ? "my-2 bg-darkGray border-darkGray"
                      : "my-2"
                  }
                  onPress={() =>
                    item?.status === updateCustomisationStatus
                      ? null
                      : setUpdateCustomisationStatusDialogBox(true)
                  }
                />
              </div>
            ) : null}
          </div>
        }
      />

      <DialogBox
        title={
          "Confirming the update of Customisation status. Are you sure you'd like to proceed?"
        }
        open={updateCustomisationStatusDialogBox}
        setOpen={setUpdateCustomisationStatusDialogBox}
        agreeFunc={updateCustomisationStatusFunc}
      />
    </div>
  );
}

export default CustomisationDetails;
