import React, { useState } from "react";
import CustomAccordion from "../../../../components/CustomAccordion";
import TextComp from "../../../../components/TextComp";
import { FlexDiv } from "..";
import {
  DeliveryStatuses,
  deliveryStatusValues,
} from "../../../../constants/DeliveryStatuses";
import SelectInput from "../../../../components/TextInput/SelectInput";
import DialogBox from "../../../../components/DialogBox";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import Button from "../../../../components/Button";

function DeliveryDetails({ item, setLoading, setOrderData, index }) {
  const [updateDeliveryStatus, setUpdateDeliveryStatus] = useState(
    item?.deliveryData?.deliveryStatus
  );
  const [updateDeliveryStatusDialogBox, setUpdateDeliveryStatusDialogBox] =
    useState(false);

  const updateDeliveryStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updateDeliveryStatusCollection",
      data: {
        orderId: item?.orderId,
        deliveryStatus: updateDeliveryStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setOrderData((prevOrderData) =>
            prevOrderData.map((order, i) =>
              i === index
                ? {
                    ...order,
                    deliveryData: {
                      ...order.deliveryData,
                      deliveryStatus: updateDeliveryStatus,
                    },
                  }
                : order
            )
          );

          successToastify(SuccessTexts.deliveryStatusUpdated);
        }
      })
      .catch((err) => {
        console.log(err);
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdateDeliveryStatusDialogBox(false);
        setLoading(false);
      });
  };

  return (
    <div className="py-2">
      <CustomAccordion
        title={"Delivery Details"}
        details={
          <div>
            <TextComp
              text={`${item?.deliveryData?.deliveryAddress?.address1}, ${item?.deliveryData?.deliveryAddress?.address2}, ${item?.deliveryData?.deliveryAddress?.city}, ${item?.deliveryData?.deliveryAddress?.province}, ${item?.deliveryData?.deliveryAddress?.zip}`}
              customStyle={"font-light tracking-wider"}
            />
            <div className="my-5">
              <FlexDiv
                title={"Name"}
                value={`${item?.deliveryData?.deliveryAddress?.first_name} ${item?.deliveryData?.deliveryAddress?.last_name}`}
              />
              <FlexDiv
                title={"Contact Number"}
                value={`${item?.deliveryData?.deliveryAddress?.phone}`}
              />
              <FlexDiv
                title={"Delivery Status"}
                value={`${item?.deliveryData?.deliveryStatus}`}
              />
              {item?.deliveryData?.deliveryStatus !==
              deliveryStatusValues?.delivered ? (
                <div className="my-5">
                  <SelectInput
                    label={"Update order status"}
                    values={DeliveryStatuses}
                    id="demo-controlled-open-select"
                    onChange={(e) => setUpdateDeliveryStatus(e.target.value)}
                    defaultValue={updateDeliveryStatus}
                  />
                  <Button
                    btnText={"UPDATE DELIVERY STATUS"}
                    customStyle={
                      item?.deliveryData?.deliveryStatus ===
                      updateDeliveryStatus
                        ? "my-2 bg-darkGray border-darkGray"
                        : "my-2"
                    }
                    onPress={() =>
                      item?.deliveryData?.deliveryStatus ===
                      updateDeliveryStatus
                        ? null
                        : setUpdateDeliveryStatusDialogBox(true)
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
        }
      />

      <DialogBox
        title={
          "Confirming the update of delivery status. Are you sure you'd like to proceed?"
        }
        open={updateDeliveryStatusDialogBox}
        setOpen={setUpdateDeliveryStatusDialogBox}
        agreeFunc={updateDeliveryStatusFunc}
      />
    </div>
  );
}

export default DeliveryDetails;
