import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Colors } from "../../../constants/Colors";
import { TextField } from "@mui/material";

function DateInput({ label, onChange, value, error, helperText }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer components={["DatePicker"]}> */}
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY"
        slotProps={{
          textField: {
            error: error || false,
            helperText: helperText || "",
          },
        }}
        sx={{
          width: "100%",
          fontSize: 16,
          "& .MuiOutlinedInput-notchedOutline": {
            color: Colors.black,
            borderColor: Colors.black,
          },

          "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              color: Colors.red,
              borderColor: Colors.red,
            },
          },

          "&.Mui-focused": {
            fontSize: 17,
            "&.Mui-error": {
              "& .MuiOutlinedInput-notchedOutline": {
                color: Colors.red,
                borderColor: Colors.red,
              },
            },

            "& .MuiOutlinedInput-notchedOutline": {
              color: Colors.black,
              borderColor: Colors.black,
            },
          },
        }}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}

export default DateInput;
