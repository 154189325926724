import React, { useEffect, useState } from "react";
import NavbarAndFooterComp from "../../components/NavbarAndFooterComp";

import TextComp from "../../components/TextComp";
import { useParams } from "react-router-dom";
import { RoutesFetch } from "../../config/RoutesFetch";
import { ErrorTexts } from "../../constants/ErrorTexts";
import Loader from "../../components/Loader";
import { IoShareOutline } from "react-icons/io5";
import { Colors } from "../../constants/Colors";
import Button, { ButtonWithIcon, OutlineButton } from "../../components/Button";
import { TiHeartOutline, TiHeartFullOutline } from "react-icons/ti";
import CustomAccordion from "../../components/CustomAccordion";
import { CiDeliveryTruck } from "react-icons/ci";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { DiscountCalculation } from "../../config/PriceCalculation";
import {
  errorToastify,
  infoToastify,
  successToastify,
} from "../../components/Toastify";
import { SuccessTexts } from "../../constants/SuccessTexts";
import { EnvConfig } from "../../config/EnvConfig";
import SubHeaders from "./SubHeaders";
import DescriptionText from "./DescriptionText";
import Coupons from "./Coupons";
import Returns from "./Returns";
import { useSelector, useDispatch } from "react-redux";
import {
  addItemInSavedList,
  removeItemInSavedList,
} from "../../config/SavedItemsFunc";
import { useNavigate } from "react-router-dom";
import PaymentOptions from "./PaymentOptions";
import RouteWaiter from "../../components/RouteWaiter";
import {
  addItemToBag,
  addItemToBagSingleCheckout,
} from "../../config/BagItemsFunc";
import { FaWandMagicSparkles } from "react-icons/fa6";
import SingleCheckoutDrawer from "../../components/ProductList/SingleCheckoutDrawer";

function ProductView() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData?.data);

  const location = useLocation();
  const { productId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [productData, setProductdata] = useState(null);
  const [errorText, setErrorText] = useState("");

  const [selectedVariantWithDefault, setSelectedVariantWithDefault] =
    useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [savedItem, setSavedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const [showSingleCheckoutDrawer, setShowSingleCheckoutDrawer] =
    useState(false);

  const currentProductLink = `${EnvConfig.websiteDomainUrl}${location.pathname}`;

  const [outOfStock, setOutOfStock] = useState(false);

  useEffect(() => {
    getProductDetails();
  }, [productId]);

  const getProductDetails = () => {
    setLoading(true);

    RoutesFetch({
      method: "get",
      url: "/getProductDetailsById",
      params: {
        productId,
      },
    })
      .then((response) => {
        if (response.msg === "fail") {
          setErrorText(ErrorTexts.sww);
        } else {
          setErrorText("");
          setProductdata(response?.productData);
          setSelectedVariantWithDefault(response?.productData?.variants[0]);
          setSavedItem(response?.productData?.inWishlist);

          for (let i = 0; i < response?.productData?.variants?.length; i++) {
            if (response?.productData?.variants[i]?.inventory_quantity > 0) {
              break;
            } else {
              if (response?.productData?.variants?.length - 1 === i) {
                setOutOfStock(true);
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        setErrorText(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const orderFunc = (from) => {
    if (productData?.variants?.length > 1 && selectedVariant === null) {
      errorToastify(ErrorTexts.selectSize);
    } else if (selectedVariant?.inventory_quantity < quantity) {
      infoToastify(
        `Currently, the maximum order quantity available is ${selectedVariant?.inventory_quantity} pieces.`
      );
    } else {
      if (from === "buyNow") {
        addItemToBagSingleCheckout({
          productId: productData?.id,
          variantId: selectedVariantWithDefault?.id,
          quantity,
          setLoading,
          setShowSingleCheckoutDrawer,
        });
      } else {
        addItemToBag({
          productId: productData?.id,
          variantId: selectedVariantWithDefault?.id,
          quantity,
          setLoading,
          dispatch,
        });
      }
    }
  };

  return (
    <NavbarAndFooterComp
      children={
        <>
          <RouteWaiter
            loading={loading}
            error={errorText}
            data={productData}
            children={
              <div className="pb-20 md:p-5 lg:p-10">
                <div className="lg:grid grid-cols-2 place-items-start">
                  <div className="lg:hidden">
                    <Carousel>
                      {productData?.images?.map((item, index) => (
                        <div className="bg-lightGray" key={index}>
                          <img
                            src={item?.src}
                            alt=""
                            className="object-contain"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>

                  <div className="lg:overflow-hidden hidden lg:grid grid-cols-2 col-span-1 lg:pr-5">
                    {productData?.images?.map((item, index) => (
                      <div
                        key={index}
                        className="cursor-pointer col-span-1 mb-5 ml-5"
                      >
                        <img
                          src={item?.src}
                          className="rounded object-contain"
                        />
                      </div>
                    ))}
                  </div>

                  <div className="px-5 lg:pl-5 col-span-1">
                    <div className="border-b-2 border-black pb-2">
                      <div className="flex items-center justify-between mb-2">
                        <TextComp
                          text={productData?.vendor?.toUpperCase()}
                          customStyle={
                            "text-darkGray tracking-widest text-xs font-medium cursor-pointer"
                          }
                        />

                        <CopyToClipboard
                          text={currentProductLink}
                          onCopy={() =>
                            successToastify(SuccessTexts.SharedLinkCopies)
                          }
                        >
                          <div
                            typeof="button"
                            className="flex items-center cursor-pointer"
                          >
                            <TextComp
                              text={"share"}
                              customStyle={
                                "text-rosePink underline text-sm tracking-widest"
                              }
                            />
                            <IoShareOutline
                              color={Colors.rosePink}
                              className="ml-0.5"
                            />
                          </div>
                        </CopyToClipboard>
                      </div>

                      <TextComp
                        text={productData?.title?.toUpperCase()}
                        customStyle={
                          "text-black tracking-widest text-3xl font-light"
                        }
                      />

                      {productData?.tags?.match(/variant - (.+?)(?:,|$)/) ? (
                        <TextComp
                          customStyle={
                            "text-sm text-darkGray font-medium mt-2 tracking-widest"
                          }
                          text={productData?.tags
                            ?.match(/variant - (.+?)(?:,|$)/)[1]
                            .toUpperCase()}
                        />
                      ) : (
                        <TextComp
                          customStyle={
                            "text-sm text-darkGray font-medium mt-2 tracking-widest"
                          }
                          text={"ORIGINALS"}
                        />
                      )}
                    </div>

                    <div className="mt-5 flex items-center justify-between">
                      <div>
                        <div className="flex flex-row items-end">
                          <TextComp
                            text={`₹ ${Math.floor(
                              selectedVariantWithDefault?.price
                            )}`}
                            customStyle={"text-2xl font-medium pr-2"}
                          />

                          {selectedVariantWithDefault?.compare_at_price ===
                            "0" ||
                          selectedVariantWithDefault?.compare_at_price === 0 ||
                          !selectedVariantWithDefault?.compare_at_price ? null : (
                            <>
                              <TextComp
                                customStyle="pr-2 text-sm text-darkGray line-through pb-1"
                                text={`MRP ₹ ${Math.floor(
                                  selectedVariantWithDefault?.compare_at_price
                                )}`}
                              />

                              <TextComp
                                customStyle="pr-2 text-sm font-medium text-rosePink pb-1"
                                text={`(${DiscountCalculation(
                                  selectedVariantWithDefault?.price,
                                  selectedVariantWithDefault?.compare_at_price
                                )} % OFF)`}
                              />
                            </>
                          )}
                        </div>

                        <TextComp
                          customStyle="text-green text-xs font-medium"
                          text={"inclusive of all taxes"}
                        />
                      </div>

                      {savedItem === true ? (
                        <div
                          onClick={() => {
                            removeItemInSavedList({
                              setSavedItem,
                              genieaseCustomerId: userData?.genieaseCustomerId,
                              productId: productData?.id,
                            });
                          }}
                        >
                          <TiHeartFullOutline
                            color={Colors.rosePink}
                            size={24}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            addItemInSavedList({
                              setSavedItem,
                              genieaseCustomerId: userData?.genieaseCustomerId,
                              productId: productData?.id,
                            });
                          }}
                        >
                          <TiHeartOutline color={Colors.black} size={24} />
                        </div>
                      )}
                    </div>

                    <div className="mt-5">
                      {productData?.variants?.length > 1 && (
                        <div className="mt-5">
                          <SubHeaders text={"SELECT SIZE"} />

                          <div className="flex flex-wrap mt-2 -mx-2 items-center">
                            {productData?.variants?.map((item, index) => {
                              return item?.inventory_quantity > 0 ? (
                                <div
                                  key={index}
                                  className={
                                    selectedVariant &&
                                    selectedVariant?.id === item?.id
                                      ? "border-2 border-black w-16 h-16 rounded p-2 m-2 flex items-center justify-center cursor-pointer"
                                      : "border border-darkGray w-16 h-16 rounded p-2 m-2 flex items-center justify-center cursor-pointer"
                                  }
                                  onClick={() => {
                                    setSelectedVariantWithDefault(item);
                                    setSelectedVariant(item);
                                  }}
                                >
                                  <TextComp
                                    customStyle={
                                      selectedVariant &&
                                      selectedVariant?.id === item?.id
                                        ? "text-[12px] text-black tracking-wider font-medium"
                                        : "text-[12px] text-darkGray tracking-wider"
                                    }
                                    text={item?.title}
                                  />
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className={
                                    "relative border border-darkGray bg-lightGray w-16 h-16 rounded p-2 m-2 flex items-center justify-center cursor-pointer"
                                  }
                                >
                                  <div class="absolute h-[85px] w-[85px] border-t border-t-darkGray rotate-45 mt-[60px] mr-[60px]"></div>
                                  <TextComp
                                    customStyle={
                                      selectedVariant &&
                                      selectedVariant?.id === item?.id
                                        ? "text-[12px] text-black tracking-wider font-medium"
                                        : "text-[12px] text-darkGray tracking-wider"
                                    }
                                    text={item?.title}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      {outOfStock === true ? (
                        <div className="mt-5">
                          <OutlineButton btnText={"SOLD OUT"} />
                        </div>
                      ) : (
                        <>
                          <div className="mt-5 w-fit bg-lightGray px-5 text-sm font-medium flex flex-row items-center shadow-sm rounded">
                            <div
                              onClick={() =>
                                quantity <= 1 ? null : setQuantity(quantity - 1)
                              }
                            >
                              <TextComp
                                customStyle="cursor-pointer text-xl pr-4 py-2 border-r-2 border-white"
                                text={"-"}
                              />
                            </div>
                            <TextComp
                              customStyle="px-4"
                              text={`Qty: ${quantity}`}
                            />
                            <div
                              onClick={() =>
                                // quantity >= 10 ? null :
                                setQuantity(quantity + 1)
                              }
                            >
                              <TextComp
                                customStyle="cursor-pointer text-xl pl-4 py-2 border-l-2 border-white"
                                text={"+"}
                              />
                            </div>
                          </div>

                          <div className="flex items-center w-full mt-5">
                            <div
                              className="w-1/2 pr-2"
                              onClick={() => orderFunc("addToBag")}
                            >
                              <OutlineButton btnText={"ADD TO BAG"} />
                            </div>

                            <div
                              className="w-1/2 pl-2"
                              onClick={() => orderFunc("buyNow")}
                            >
                              <OutlineButton btnText={"BUY NOW"} />
                            </div>
                          </div>
                        </>
                      )}

                      {productData?.tags?.match(
                        /customisation - (.+?)(?:,|$)/
                      )[1] === "true" ? (
                        <div className="mt-5">
                          <ButtonWithIcon
                            btnText={"CUSTOMISE BY YOU"}
                            Icon={FaWandMagicSparkles}
                            onPress={() => {
                              navigate("/customisation", {
                                state: {
                                  productTitle: productData?.title,
                                },
                              });
                            }}
                          />
                        </div>
                      ) : null}

                      {!productData?.customVariants ||
                      productData?.customVariants?.length === 0 ? null : (
                        <div className="mt-5">
                          <SubHeaders text={"MORE COLORS"} />

                          <div className="flex flex-wrap mt-2 -mx-2 items-center">
                            {productData?.customVariants?.map((item, index) => (
                              <div
                                key={index}
                                className={
                                  productData?.id === item?.id
                                    ? "flex flex-col items-center m-2 w-16 cursor-pointer border-2 border-black rounded p-1"
                                    : "flex flex-col items-center m-2 w-16 cursor-pointer rounded"
                                }
                                onClick={() => {
                                  navigate(`/product/${item?.id}`);
                                }}
                              >
                                <img
                                  src={item?.image?.src}
                                  className="w-16 h-16 rounded"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-5">
                      <SubHeaders text={"PRODUCT DESCRIPTION"} />

                      <DescriptionText
                        text={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: productData?.body_html,
                            }}
                          />
                        }
                      />
                    </div>

                    <div className="mt-5">
                      <div className="flex flex-row items-center">
                        <SubHeaders text={"DELIVERY OPTIONS"} />

                        <CiDeliveryTruck
                          size={20}
                          color={Colors.darkGray}
                          className="mx-3"
                        />
                      </div>

                      <div>
                        <DescriptionText
                          text={
                            "Products will be delivered within 3-4 days. If there is any customisation / personalisation in the product then delivery time will be informed accordingly."
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="mt-5">
                    <CustomAccordion
                      title={<SubHeaders text={"COUPONS"} />}
                      details={<Coupons />}
                    />
                  </div> */}

                    <div className="mt-5">
                      <CustomAccordion
                        title={<SubHeaders text={"RETURNS & EXCHANGE"} />}
                        details={<Returns productTag={productData?.tags} />}
                      />
                      <div className="mt-5">
                        <CustomAccordion
                          title={<SubHeaders text={"PAYMENT OPTIONS"} />}
                          details={<PaymentOptions />}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />

          <SingleCheckoutDrawer
            showSingleCheckoutDrawer={showSingleCheckoutDrawer}
            setShowSingleCheckoutDrawer={setShowSingleCheckoutDrawer}
          />
        </>
      }
    />
  );
}

export default ProductView;
