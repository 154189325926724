import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { Body, SubHeading } from "../Terms";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import { ImpValues } from "../../../constants/ImpValues";

function Policies() {
  return (
    <div>
      <NavbarAndFooterComp
        children={
          <div>
            <PageHeader text={"PRIVACY POLICY"} />

            <div className="py-10 mx-5 md:px-28 lg:px-40 lg:pt-20">
              <div className="mb-10">
                <SubHeading text="Information We Collect" />
                <Body
                  text={`Personal Information: Name, address, email, phone number, and payment details for order processing.`}
                />
                <Body
                  text={`Non-Personal Information: Browser type, IP address, and website usage data to enhance user experience.`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="How We Use Your Information" />
                <Body text={`To process and deliver orders.`} />
                <Body text={`To improve our website functionality.`} />
                <Body
                  text={`To send promotional offers (with your consent).`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Sharing of Information" />
                <Body
                  text={`We share customer details only with third-party services necessary for order fulfillment (e.g., delivery partners, payment gateways).`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Data Security" />
                <Body
                  text={`Your information is encrypted and stored securely.`}
                />
                <Body
                  text={`geniease complies with applicable data protection laws.`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Customer Rights" />
                <Body
                  text={`You have the right to request access, correction, or deletion of your personal data.`}
                />
                <Body
                  text={`For any inquiries, contact us at ${ImpValues?.supportMail}.`}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Policies;
