import React, { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroller";

import { RoutesFetch } from "../../../config/RoutesFetch";
import { ErrorTexts } from "../../../constants/ErrorTexts";
import { InfiniteScrollLoader } from "../../Loader";
import { errorToastify } from "../../Toastify";
import { OutlineButton } from "../../Button";
import TextComp from "../../TextComp";
import FiltersList from "../../FiltersList";

function InfiniteScrollCompProductList({
  url,
  responseDataName,
  setLoading,
  setErrorText,
  errorToastifyBoolean = false,
  data,
  setData,
  params,
  children,
  valueOfUseEffectArray = [],
  noLoadMore = false,
}) {
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [nextPageInfo, setNextPageInfo] = useState(null);

  useEffect(() => {
    setLoading(true);

    fetchData(null);
  }, [...valueOfUseEffectArray]);

  const fetchData = (page_info) => {
    RoutesFetch({
      method: "get",
      url,
      params: {
        page_info,
        ...params,
      },
    })
      .then((response) => {
        if (response.msg === "fail") {
          setLoading(false);

          errorToastifyBoolean === true
            ? errorToastify(ErrorTexts.sww)
            : setErrorText(ErrorTexts.sww);
        } else {
          if (errorToastifyBoolean === false) {
            setErrorText("");
          }

          const resDataArray = response[responseDataName];
          const productIds = resDataArray?.map((item) =>
            item?.node?.id?.replace("gid://shopify/Product/", "")
          );

          RoutesFetch({
            method: "get",
            url: "/getUserWishlistIds",
            params: {
              productIds,
            },
          })
            .then((savedItemResponse) => {
              if (savedItemResponse?.msg === "fail") {
                !data || !page_info
                  ? setData([...resDataArray])
                  : setData([...data, ...resDataArray]);
              } else {
                const wishlistIds = savedItemResponse?.wishlistIds;
                if (wishlistIds?.length === 0) {
                  !data || !page_info
                    ? setData([...resDataArray])
                    : setData([...data, ...resDataArray]);
                } else {
                  for (let i = 0; i < resDataArray?.length; i++) {
                    for (let j = 0; j < wishlistIds?.length; j++) {
                      if (
                        resDataArray[i]?.node?.id?.replace(
                          "gid://shopify/Product/",
                          ""
                        ) === wishlistIds[j]?.productId.toString()
                      ) {
                        resDataArray[i] = {
                          ...resDataArray[i],
                          inWishlist: true,
                        };
                        wishlistIds.splice(j, 1);
                        break;
                      }
                    }
                  }

                  !data || !page_info
                    ? setData([...resDataArray])
                    : setData([...data, ...resDataArray]);
                }
              }
            })
            .catch((err) => {
              !data || !page_info
                ? setData([...resDataArray])
                : setData([...data, ...resDataArray]);
            })
            .finally(() => {
              if (!response["pageInfo"]?.endCursor) {
                setNextPageInfo(null);
                setIsMoreLoading(false);
              } else {
                setNextPageInfo(response["pageInfo"]?.endCursor);
                setIsMoreLoading(true);
              }
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        setLoading(false);

        errorToastifyBoolean === true
          ? errorToastify(ErrorTexts.sww)
          : setErrorText(ErrorTexts.sww);
      })
      .finally(() => {});
  };

  return (
    <>
      {/* <FiltersList /> */}

      <InfiniteScroll
        pageStart={0}
        loadMore={() => fetchData(nextPageInfo)}
        hasMore={!nextPageInfo || noLoadMore === true ? null : isMoreLoading}
        loader={
          <div className="flex flex-col items-center">
            <InfiniteScrollLoader />
          </div>
        }
      >
        {children}
      </InfiniteScroll>
    </>
  );
}

export default InfiniteScrollCompProductList;
