import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { store } from "./App/config/Redux/Store";
import App from "./App";
import { ErrorBoundary } from "react-error-boundary";
import SWWComp from "./App/components/BlankScreen/SWWComp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // react.strictmode made useEffect call twice in local due to some inner functionallity so your api will not call twice in production, so don't worry :)
  // <React.StrictMode>
  <ErrorBoundary fallback={<SWWComp />}>
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
