export const ErrorTexts = {
  sww: "Something went wrong",
  otpsentfail: "OTP sent failed! Please Try Again",
  invalidOTP: "Please enter a valid OTP!",
  alreadyTaken: "This data is already registered with this account!",
  cannotDeleteDefaultAddress: "You can not delete your default address!",
  enterValidPincode: "Please enter a valid pincode!",
  selectSize: "Please select a size!",
  selectAddress: "Please select your delivery address!",
  paymentFailed: "Payment failed! Please try again.",
  paymentCancelled: "Payment cancelled by user.",
  paymentDispute:
    "Payment processing failed. If any amount has been deducted, will refunded within 48 hours.",
  addressNotFetch:
    "Apologies, we encountered an issue and are unable to retrieve your delivery addresses at the moment.",
  noAddress:
    "To proceed with your order, please ensure that you provide a delivery address. Kindly add your delivery address by selecting it from the options provided.",
  requiredFields: "Please fill all the required fields!",
};

export const ErrorDotMessage = (error) => {
  return error.message; // .message is a keyword to read throw errors
};
