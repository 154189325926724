import React, { useState } from "react";
import TextComp from "../../components/TextComp";

import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import { logoutFunc } from "../../config/LogoutFunc";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { CollectionIds } from "../../config/CollectionIds";
import { ProductMenuList } from "../../constants/ProductMenuList";

function ProductsMenu({ setShowMenuDrawer }) {
  const userData = useSelector((state) => state?.userData?.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const [loading, setLoading] = useState(false);

  const ListTextComp = ({ text, onClick }) => {
    return (
      <div
        onClick={onClick}
        className="flex flex-row items-center justify-between my-7"
      >
        <TextComp
          text={text}
          customStyle={"text-xl font-light tracking-wider cursor-pointer"}
        />
        <div className="ml-1 mt-1">
          <IoIosArrowForward />
        </div>
      </div>
    );
  };

  const LowerListTextComp = ({ text, link }) => {
    return (
      <div
        onClick={() => {
          text === "Sign Out"
            ? logoutFunc({
                navigate,
                pathname,
                dispatch,
                setLoading,
                setShowMenuDrawer,
              })
            : navigate(link);
        }}
      >
        <TextComp
          text={text}
          customStyle={
            "text-sm border-b pb-0.5 w-fit tracking-wider cursor-pointer my-7 text-darkGray"
          }
        />
      </div>
    );
  };

  return (
    <div className="my-2 mb-10 mx-10 md:mx-10 lg:mx-20">
      {ProductMenuList?.map((item, index) => (
        <ListTextComp
          key={index}
          text={item?.title}
          onClick={() => {
            navigate(item?.navigation);
          }}
        />
      ))}

      <div className="mt-10">
        {userData?.mobileNumberVerified === true ? (
          <>
            <LowerListTextComp text={"My Account"} link="/myaccount" />
            <LowerListTextComp text={"My Orders"} link="/myorders" />
            <LowerListTextComp
              text={"My Customisation"}
              link="/customisationorders"
            />
          </>
        ) : null}

        <LowerListTextComp text={"Saved Items"} link="/saveditems" />
        <LowerListTextComp text={"Contact Us"} link="/contact-us" />
        {userData?.mobileNumberVerified === true ? (
          <LowerListTextComp text={"Sign Out"} />
        ) : (
          <LowerListTextComp text={"Sign In"} link="/login" />
        )}
      </div>

      <Loader loading={loading} />
    </div>
  );
}

export default ProductsMenu;
