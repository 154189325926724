import React, { useEffect, useState } from "react";
import TextComp from "../../components/TextComp";

import { RoutesFetch } from "../../config/RoutesFetch";
import { errorToastify } from "../../components/Toastify";
import { ErrorTexts } from "../../constants/ErrorTexts";

import CheckoutCommonFlow from "./checkoutCommonFlow";

function Bag({ setShowBagDrawer }) {
  const [loading, setLoading] = useState(false);
  const [bagData, setBagData] = useState(null);
  const [updateBagData, setUpdateBagData] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [updateCouponCode, setUpdateCouponCode] = useState(0);

  useEffect(() => {
    getBagProducts();
  }, [updateBagData]);

  const getBagProducts = () => {
    setLoading(true);

    RoutesFetch({
      method: "get",
      url: "/getBagCollectionProducts",
    })
      .then((res) => {
        if (res.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          if (res?.couponCode) {
            updateBagData === 0 && setCouponCode(res?.couponCode);
            setUpdateCouponCode(updateCouponCode + 1);
          } else {
            setCouponCode("");
          }
          setBagData(res?.bagData);
        }
      })
      .catch((err) => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        if (!couponCode) {
          setLoading(false);
        }
      });
  };

  return (
    <div className="my-2 mb-10 mx-5 md:mx-10 lg:mx-20">
      <TextComp
        text={"SHOPPING BAG"}
        customStyle={"text-2xl font-medium tracking-widest"}
      />

      <CheckoutCommonFlow
        loading={loading}
        bagData={bagData}
        setLoading={setLoading}
        setBagData={setBagData}
        setUpdateBagData={setUpdateBagData}
        updateBagData={updateBagData}
        setCouponCode={setCouponCode}
        couponCode={couponCode}
        updateCouponCode={updateCouponCode}
      />
    </div>
  );
}

export default Bag;
