import React from "react";
import { FlexDiv } from "..";
import CustomAccordion from "../../../../components/CustomAccordion";

function UserDetails({ item }) {
  return (
    <div className="py-2">
      <CustomAccordion
        title={"User Details"}
        details={
          <div>
            {!item?.userData?.firstName ? null : (
              <FlexDiv
                title={"Name"}
                value={`${item?.userData?.firstName} ${item?.userData?.lastName}`}
              />
            )}
            {!item?.userData?.email ? null : (
              <FlexDiv title={"Email"} value={`${item?.userData?.email}`} />
            )}
            <FlexDiv
              title={"Mobile Number"}
              value={`${item?.userData?.mobileNumber}`}
            />
          </div>
        }
      />
    </div>
  );
}

export default UserDetails;
