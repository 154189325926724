export const returnCancelStatusValues = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
};

export const returnCancelStatus = [
  { label: "Pending", value: returnCancelStatusValues?.pending },
  { label: "Rejected", value: returnCancelStatusValues?.rejected },
  { label: "Approved", value: returnCancelStatusValues?.approved },
];

export const returnCancelStatusFilterValues = {
  return: "return",
  // exchange: "exchange",
  cancellation: "cancellation",
  all: "all",
};

export const returnCancelFilterStatus = [
  { label: "Return", value: returnCancelStatusFilterValues?.return },
  // { label: "Exchange", value: returnCancelStatusFilterValues?.exchange },
  {
    label: "Cancellation",
    value: returnCancelStatusFilterValues?.cancellation,
  },
  { label: "All", value: returnCancelStatusFilterValues?.all },
];
