import React, { useEffect, useState } from "react";
import { OutlineTextInput } from "../../../components/TextInput";
import Button from "../../../components/Button";
import TextComp from "../../../components/TextComp";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { RoutesFetch } from "../../../config/RoutesFetch";
import { errorToastify, successToastify } from "../../../components/Toastify";
import { ErrorDotMessage, ErrorTexts } from "../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../constants/SuccessTexts";
import * as yup from "yup";
import { setData } from "../../../config/Redux/UserData";
import { useDispatch } from "react-redux";

function Otpscreen({
  mobileNumber,
  otpValue,
  setOtpValue,
  setLoading,
  mainDivCustomStyle,
  setMobileVerificationModal,
  setUpdateBagData,
  updateBagData,
  setOtpSent,
  otpSent,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [seconds, setSeconds] = useState(59);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setOtpValue("");
  }, [otpSent]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(myInterval);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  const resendOtp = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/loginWithMobileNumber",
      data: {
        mobileNumber: `+91${mobileNumber}`,
      },
    })
      .then((response) => {
        if (response.msg === "fail") {
          errorToastify(ErrorTexts.otpsentfail);
        } else {
          setSeconds(59);
          successToastify(SuccessTexts.otpSend);
        }
      })
      .catch((err) => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyOtp = () => {
    const otpValidation = yup.object().shape({
      otpValue: yup
        .string()
        .required("Please enter a valid OTP")
        .matches(`^[0-9]+$`, "Please enter a valid OTP")
        .min(4, "Please enter a valid OTP")
        .max(4, "Please enter a valid OTP"),
    });

    otpValidation
      .validate({
        otpValue,
      })
      .then(() => {
        setLoading(true);
        setErrorText("");

        RoutesFetch({
          method: "post",
          url: "/verifyOtp",
          data: {
            mobileNumber: `+91${mobileNumber}`,
            otpValue,
          },
        })
          .then((response) => {
            if (response.msg === "fail") {
              errorToastify(ErrorTexts.sww);
            } else if (response.msg === "invalidOtp") {
              errorToastify(ErrorTexts.invalidOTP);
            } else {
              dispatch(setData(response.userData));

              if (setMobileVerificationModal) {
                setUpdateBagData && setUpdateBagData(updateBagData + 1);
                setMobileVerificationModal(false);
              } else {
                if (response.newUser === true) {
                  navigate("/personaldetails", { replace: true });
                } else {
                  navigate(-1, { replace: true });
                }
              }
            }
          })
          .catch((err) => {
            errorToastify(ErrorTexts.sww);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setErrorText(ErrorDotMessage(err));
      });
  };

  return (
    <div
      className={`w-full md:w-1/2 lg:w-1/2 flex flex-col items-center ${mainDivCustomStyle}`}
    >
      <div className="mt-5 lg:mt-10 flex flex-row items-end">
        <TextComp
          text={`OTP successfully sent on mobile number +91${mobileNumber}`}
          customStyle={"text-xs tracking-wider mr-1"}
        />
        <CiEdit
          className="text-red cursor-pointer"
          size={18}
          onClick={() => {
            setOtpSent(false);
          }}
        />
      </div>

      <div className="mt-4 w-full">
        <OutlineTextInput
          label={"Enter OTP"}
          value={otpValue}
          onChange={(e) => {
            setOtpValue(e.target.value);
          }}
          error={errorText === "" ? false : true}
          helperText={errorText === "" ? null : errorText}
          inputProps={{ maxLength: 4 }}
        />
      </div>

      <div
        className="w-full pt-2 pr-1 flex items-end justify-end"
        onClick={seconds !== 0 ? null : resendOtp}
      >
        <TextComp
          customStyle="text-black text-xs font-medium cursor-pointer border-b pb-0.2"
          text={
            seconds !== 0
              ? `00:${String(seconds).length === 1 ? `0${seconds}` : seconds}`
              : "Resend OTP"
          }
        />
      </div>

      <div className="mt-5">
        <p className="text-xs tracking-wider text-darkGray">
          By choosing "CONFIRM", you confirm that you agree to our{" "}
          <span
            className="text-black border-b pb-0.2 cursor-pointer"
            onClick={() => {
              navigate("/terms");
            }}
          >
            Terms of Use
          </span>
          , that you have acknowledged our{" "}
          <span
            className="text-black border-b pb-0.2 cursor-pointer"
            onClick={() => {
              navigate("/policies");
            }}
          >
            privacy policy
          </span>
          , and that you want to create your geniease profile.
        </p>
      </div>

      <div className="mt-5 w-full">
        <Button btnText={"CONFIRM"} onPress={verifyOtp} />
      </div>
    </div>
  );
}

export default Otpscreen;
