import React, { useState } from "react";
import CustomAccordion from "../../../../components/CustomAccordion";
import TextComp from "../../../../components/TextComp";
import { FlexDiv } from "..";

import SelectInput from "../../../../components/TextInput/SelectInput";
import DialogBox from "../../../../components/DialogBox";
import { RoutesFetch } from "../../../../config/RoutesFetch";
import {
  errorToastify,
  successToastify,
} from "../../../../components/Toastify";
import { ErrorTexts } from "../../../../constants/ErrorTexts";
import { SuccessTexts } from "../../../../constants/SuccessTexts";
import Button from "../../../../components/Button";
import {
  returnCancelStatus,
  returnCancelStatusValues,
} from "../../../../constants/ReturnCancelStatus";

function CancellationDetails({ item, setLoading, setOrderData, index }) {
  const [updateCancellationStatus, setUpdateCancellationStatus] = useState(
    item?.cancellationData?.cancellationStatus
  );

  const [
    updateCancellationStatusDialogBox,
    setUpdateCancellationStatusDialogBox,
  ] = useState(false);

  const updateCancellationStatusFunc = () => {
    setLoading(true);

    RoutesFetch({
      method: "post",
      url: "/updateCancellationStatus",
      data: {
        orderId: item?.orderId,
        cancellationStatus: updateCancellationStatus,
      },
    })
      .then((res) => {
        if (res?.msg === "fail") {
          errorToastify(ErrorTexts.sww);
        } else {
          setOrderData((prevOrderData) =>
            prevOrderData.map((order, i) =>
              i === index
                ? {
                    ...order,
                    cancellationData: {
                      ...order.cancellationData,
                      cancellationStatus: updateCancellationStatus,
                    },
                  }
                : order
            )
          );

          successToastify(SuccessTexts.cancellationStatusUpdate);
        }
      })
      .catch(() => {
        errorToastify(ErrorTexts.sww);
      })
      .finally(() => {
        setUpdateCancellationStatusDialogBox(false);
        setLoading(false);
      });
  };

  return (
    <div className="py-2">
      <CustomAccordion
        title={"Cancellation Details"}
        details={
          <div>
            <FlexDiv
              title={"Cancellation Status"}
              value={item?.cancellationData?.cancellationStatus}
            />

            {item?.cancellationData?.cancellationStatus ===
            returnCancelStatusValues?.pending ? (
              <div className="my-5">
                <SelectInput
                  label={"Update cancellation status"}
                  values={returnCancelStatus}
                  id="demo-controlled-open-select"
                  onChange={(e) => setUpdateCancellationStatus(e.target.value)}
                  defaultValue={updateCancellationStatus}
                />
                <Button
                  btnText={"UPDATE CANCELLATION STATUS"}
                  customStyle={
                    item?.cancellationData?.cancellationStatus ===
                    updateCancellationStatus
                      ? "my-2 bg-darkGray border-darkGray"
                      : "my-2"
                  }
                  onPress={() =>
                    item?.cancellationData?.cancellationStatus ===
                    updateCancellationStatus
                      ? null
                      : setUpdateCancellationStatusDialogBox(true)
                  }
                />
              </div>
            ) : null}
          </div>
        }
      />

      <DialogBox
        title={
          "Approve the status only after order has been returned to your warehouse."
        }
        open={updateCancellationStatusDialogBox}
        setOpen={setUpdateCancellationStatusDialogBox}
        agreeFunc={updateCancellationStatusFunc}
      />
    </div>
  );
}

export default CancellationDetails;
