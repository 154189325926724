import { CollectionIds } from "../../config/CollectionIds";

export const ProductMenuList = [
  {
    title: "New In",
    navigation: `/collections/${CollectionIds.newin}`,
  },
  {
    title: "Best Seller",
    navigation: `/collections/${CollectionIds.bestseller}`,
  },
  {
    title: "Customisation",
    navigation: `/collections/${CollectionIds.customisation}`,
  },
  {
    title: "T-shirts",
    navigation: `/collections/${CollectionIds.tshirt}`,
  },
  {
    title: "Men",
    navigation: `/collections/${CollectionIds.men}`,
  },
  {
    title: "Women",
    navigation: `/collections/${CollectionIds.women}`,
  },
  {
    title: "Fragrances",
    navigation: `/collections/${CollectionIds.fragrances}`,
  },
  {
    title: "Gifts",
    navigation: `/collections/${CollectionIds.gift}`,
  },
];
