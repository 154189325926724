import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { Colors } from "../../../../../constants/Colors";
import CustomRadioGroup from "../../../../../components/CustomRadioGroup";
import { returnStatusValues } from "../../../../../constants/DeliveryStatuses";
import CancellationForm from "./CancellationForm";
import ReturnForm from "./ReturnForm";
import TextComp from "../../../../../components/TextComp";

function RaiseRequest({
  setOpen,
  returnCancelOption,
  orderData,
  setUpdateDetails,
}) {
  return (
    <div className="my-24 flex items-center justify-center">
      <div className="bg-white mx-5 md:mx-0 w-full md:w-2/3 lg:w-1/2 p-2">
        <div className="flex flex-row items-center justify-between m-2 my-4 md:m-4">
          <TextComp
            text={
              returnCancelOption === returnStatusValues?.cancelled
                ? "CANCEL REQUEST"
                : "RETURN REQUEST"
            }
            customStyle={"text-sm text-darkGray tracking-wide"}
          />
          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setOpen(false)}
          />
        </div>

        {returnCancelOption === returnStatusValues?.cancelled ? (
          <CancellationForm
            setOpen={setOpen}
            orderData={orderData}
            setUpdateDetails={setUpdateDetails}
          />
        ) : (
          <ReturnForm
            setOpen={setOpen}
            orderData={orderData}
            setUpdateDetails={setUpdateDetails}
          />
        )}
      </div>
    </div>
  );
}

export default RaiseRequest;
