import React, { useState } from "react";

import { IoCloseOutline } from "react-icons/io5";
import { Colors } from "../../constants/Colors";
import TextComp from "../TextComp";

import MobileNumberScreen from "../../screens/Login/MobileNumberScreen";
import Otpscreen from "../../screens/Login/OtpScreen";

function MobileVerification({
  setOpen,
  setLoading,
  setUpdateBagData,
  updateBagData,
}) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  return (
    <div className="my-24 flex items-center justify-center">
      <div className="bg-white mx-5 md:mx-0 w-full md:w-2/3 lg:w-1/2 p-2">
        <div className="flex flex-col items-end m-2 my-4 md:m-4">
          <IoCloseOutline
            color={Colors.black}
            size={40}
            className="cursor-pointer hover:p-1 duration-500"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="m-2 md:m-10 flex flex-col items-center">
          <TextComp
            text={"VERIFY WITH YOUR MOBILE NUMBER"}
            customStyle={"text-xl font-light tracking-widest pb-4"}
          />

          {otpSent === false ? (
            <MobileNumberScreen
              mainDivCustomStyle={"w-full md:w-full lg:w-full"}
              setMobileNumber={setMobileNumber}
              mobileNumber={mobileNumber}
              setOtpSent={setOtpSent}
              setLoading={setLoading}
            />
          ) : (
            <Otpscreen
              mainDivCustomStyle={"w-full md:w-full lg:w-full"}
              mobileNumber={mobileNumber}
              otpValue={otpValue}
              setOtpValue={setOtpValue}
              setLoading={setLoading}
              setMobileVerificationModal={setOpen}
              setUpdateBagData={setUpdateBagData}
              updateBagData={updateBagData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileVerification;
