import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { RoutesFetch } from "../../../../../config/RoutesFetch";
import { useState } from "react";
import { ErrorTexts } from "../../../../../constants/ErrorTexts";
import RouteWaiter from "../../../../../components/RouteWaiter";
import NavbarAndFooterComp from "../../../../../components/NavbarAndFooterComp";
import { Colors } from "../../../../../constants/Colors";
import { FaBox } from "react-icons/fa";
import TextComp from "../../../../../components/TextComp";
import moment from "moment";

import { PageHeader } from "../../../../../components/PageHeader";

import DisplayProducts from "../DisplayProducts";

import CustomModal from "../../../../../components/Modal";
import RaiseRequest from "../RaiseRequest";
import {
  deliveryStatusValues,
  returnStatusValues,
} from "../../../../../constants/DeliveryStatuses";
import { returnCancelStatusValues } from "../../../../../constants/ReturnCancelStatus";
import { ImpValues } from "../../../../../constants/ImpValues";
import CustomAccordion from "../../../../../components/CustomAccordion";

function OrderDetails() {
  const { orderId } = useParams();

  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  const [errorText, setErrorText] = useState("");

  const [updateDetails, setUpdateDetails] = useState(0);
  const [returnCancelModal, setReturnCancelModal] = useState(false);
  const [returnCancelOption, setReturnCancelOption] = useState(null);

  const [returnProducts, setReturnProducts] = useState(null);

  useEffect(() => {
    setLoading(true);
    RoutesFetch({
      method: "get",
      url: "/getUserSingleOrderCollection",
      params: {
        orderId,
      },
    })
      .then((res) => {
        if (res.msg === "fail") {
          setErrorText(ErrorTexts.sww);
        }
        {
          setOrderData(res?.orderData);

          if (orderData?.returnData) {
            const returnFilterProducts =
              res?.orderData?.bagData?.collectiveBagData?.filter((products) =>
                res?.orderData?.returnData?.productIds?.some(
                  (returnIds) =>
                    products?.id === returnIds?.productId &&
                    products?.variants?.id === returnIds?.variantId
                )
              );

            setReturnProducts(returnFilterProducts);
          }
        }
      })
      .catch(() => {
        setErrorText(ErrorTexts.sww);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [updateDetails]);

  return (
    <NavbarAndFooterComp>
      <PageHeader text={"ORDER ITEMS"} />
      <RouteWaiter
        loading={loading}
        error={errorText}
        data={orderData}
        children={
          <div className="px-5 py-10 md:px-20">
            <div className="bg-white rounded shadow p-5">
              <div className="flex items-center">
                <div className="rounded-full bg-black p-3">
                  <FaBox color={Colors.white} size={20} />
                </div>
                <div>
                  <TextComp
                    text={
                      orderData?.cancellationData?.cancellationStatus ===
                      returnCancelStatusValues?.approved
                        ? "CANCELLED"
                        : orderData?.returnData?.returnStatus ===
                          returnCancelStatusValues?.approved
                        ? "RETURNED"
                        : orderData?.deliveryData?.deliveryStatus?.toUpperCase()
                    }
                    customStyle={"mx-4 text-sm font-medium tracking-widest"}
                  />
                  <TextComp
                    text={`Last updated on ${moment(
                      orderData?.updatedAt
                    ).format("DD-MM-YYYY hh:mm")}`}
                    customStyle={"mx-4 text-[9px] font-light text-darkGray"}
                  />
                </div>
              </div>

              <div>
                {orderData?.bagData?.collectiveBagData?.map(
                  (productItem, index) => (
                    <DisplayProducts
                      key={index}
                      productItem={productItem}
                      orderData={orderData}
                    />
                  )
                )}
              </div>

              <div>
                {orderData?.bagData?.getYFreeProducts?.map(
                  (productItem, index) => (
                    <DisplayProducts
                      key={index}
                      productItem={productItem}
                      orderData={orderData}
                      freeItem={true}
                    />
                  )
                )}
              </div>

              <div className="mt-5">
                {orderData?.returnData &&
                orderData?.returnData?.returnStatus ===
                  returnCancelStatusValues?.approved ? (
                  <CustomAccordion
                    title={"Returned Products"}
                    details={
                      <>
                        {returnProducts?.map((productItem, index) => (
                          <DisplayProducts
                            key={index}
                            productItem={productItem}
                            orderData={orderData}
                          />
                        ))}
                      </>
                    }
                  />
                ) : null}
              </div>

              <div className="mt-10 bg-lightGray p-5 rounded">
                <FlexDiv
                  title={"Total MRP"}
                  value={orderData?.bagData?.totalMrp}
                />
                <FlexDiv
                  title={"Total Discount"}
                  value={orderData?.bagData?.discountOnMrp}
                  color="green"
                />
                {orderData?.bagData?.totalCouponDiscount ? null : (
                  <FlexDiv
                    title={"Coupon Discount"}
                    value={orderData?.bagData?.totalCouponDiscount}
                    color="green"
                  />
                )}
                <div className="border-[0.5px] mb-2 border-darkGray" />
                <FlexDiv
                  title={"Total Amount"}
                  value={orderData?.bagData?.totalAmountToPaid}
                />
              </div>

              {/* <div className="mt-5 flex justify-end cursor-pointer">
                <TextComp
                  text="Download Invoice"
                  customStyle={"text-rosePink text-xs tracking-wide underline"}
                />
              </div> */}

              <div className="mt-5 flex justify-end cursor-pointer">
                {deliveryStatusValues?.delivered !==
                  orderData?.deliveryData?.deliveryStatus &&
                !orderData?.cancellationData ? (
                  <div
                    onClick={() => {
                      setReturnCancelModal(true);
                      setReturnCancelOption(returnStatusValues?.cancelled);
                    }}
                  >
                    <TextComp
                      text={"CANCEL REQUEST"}
                      customStyle={"w-fit items-end underline text-sm"}
                    />
                  </div>
                ) : deliveryStatusValues?.delivered ===
                    orderData?.deliveryData?.deliveryStatus &&
                  moment(orderData?.deliveryData?.deliveryUpdateDate).add(
                    parseInt(ImpValues?.exchangeReturnDays),
                    "days"
                  ) >= moment() &&
                  !orderData?.returnData ? (
                  <div
                    onClick={() => {
                      setReturnCancelModal(true);
                      setReturnCancelOption(returnStatusValues?.return);
                    }}
                  >
                    <TextComp
                      text={"RETURN REQUEST"}
                      customStyle={"w-fit items-end underline text-sm"}
                    />
                  </div>
                ) : null}
              </div>

              <div className="w-full text-center">
                {orderData?.cancellationData && !orderData?.returnData ? (
                  orderData?.cancellationData?.cancellationStatus ===
                  returnCancelStatusValues?.pending ? (
                    <TextComp
                      text={"CANCELLATION REQUEST IN PROCESS"}
                      customStyle={
                        "text-white text-sm bg-warningYellow p-2 rounded-sm"
                      }
                    />
                  ) : orderData?.cancellationData?.cancellationStatus ===
                    returnCancelStatusValues?.rejected ? (
                    <TextComp
                      text={"CANCELLATION REQUEST REJECTED"}
                      customStyle={"text-white text-sm bg-red p-2 rounded-sm"}
                    />
                  ) : orderData?.cancellationData?.cancellationStatus ===
                    returnCancelStatusValues?.approved ? (
                    <TextComp
                      text={"CANCELLATION REQUEST APPROVED"}
                      customStyle={"text-white text-sm bg-green p-2 rounded-sm"}
                    />
                  ) : null
                ) : null}
              </div>

              <div className="w-full text-center">
                {orderData?.returnData && !orderData?.cancellationData ? (
                  orderData?.returnData?.returnStatus ===
                  returnCancelStatusValues?.pending ? (
                    <TextComp
                      text={"RETURN REQUEST IN PROCESS"}
                      customStyle={
                        "text-white text-sm bg-warningYellow p-2 rounded-sm"
                      }
                    />
                  ) : orderData?.returnData?.returnStatus ===
                    returnCancelStatusValues?.rejected ? (
                    <TextComp
                      text={"RETURN REQUEST REJECTED"}
                      customStyle={"text-white text-sm bg-red p-2 rounded-sm"}
                    />
                  ) : orderData?.returnData?.returnStatus ===
                    returnCancelStatusValues?.approved ? (
                    <TextComp
                      text={"RETURN REQUEST APPROVED"}
                      customStyle={"text-white text-sm bg-green p-2 rounded-sm"}
                    />
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        }
      />

      <CustomModal
        open={returnCancelModal}
        setOpen={setReturnCancelModal}
        children={
          <RaiseRequest
            setOpen={setReturnCancelModal}
            returnCancelOption={returnCancelOption}
            orderData={orderData}
            setUpdateDetails={setUpdateDetails}
          />
        }
      />
    </NavbarAndFooterComp>
  );
}

const FlexDiv = ({ title, value, color }) => {
  return (
    <div className="flex flex-row items-center justify-between text-sm tracking-wide font-medium mb-2">
      <TextComp text={title} />
      <TextComp
        text={value}
        customStyle={color === "green" ? "text-green" : null}
      />
    </div>
  );
};

export default OrderDetails;
