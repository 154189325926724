import React from "react";
import DescriptionText from "../DescriptionText";

function Returns({ productTag }) {
  return (
    <div>
      {productTag?.match(/NoReturn - (.+?)(?:,|$)/)[1] === "true" && (
        <DescriptionText text={"No returns available on this product."} />
      )}
      <DescriptionText
        text={
          "Products must be unused, with original tags, packaging intact and claim must be raised within 7 days of delivery."
        }
      />
      <DescriptionText
        text={
          "Customized products are strictly non-returnable, only replacements are provided for eligible cases."
        }
      />
    </div>
  );
}

export default Returns;
