import React from "react";

import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import { PageHeader } from "../../../components/PageHeader";
import { ImpValues } from "../../../constants/ImpValues";

function Terms() {
  return (
    <div>
      <NavbarAndFooterComp
        children={
          <div>
            <PageHeader text={"TERMS OF USE"} />

            <div className="py-10 mx-5 md:px-28 lg:px-40 lg:pt-20">
              <div className="mb-10">
                <SubHeading text="INTRODUCTION AND OVERVIEW OF GENIEASE:" />
                <Body
                  text={`Welcome to Geniease! By accessing or using our website (${ImpValues?.supportMail}), you agree to comply with and be bound by the following terms and conditions. These terms apply to all users of the site, including browsers, customers, and merchants.`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Product Information" />
                <Body
                  text={`geniease offers a range of fashion products, including our own brand and those from global brands.`}
                />
                <Body
                  text={`Customized products are crafted to order and may slightly vary in appearance due to the handmade nature of customization (e.g., hand painting, embroidery).`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Order Placement" />
                <Body
                  text={`Orders can be placed through the website after successful account creation.`}
                />
                <Body
                  text={`All orders are subject to availability and acceptance.`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Payment Terms" />
                <Body
                  text={`geniease accepts both online payments and cash on delivery (COD).`}
                />
                <Body
                  text={`Online payments must be completed securely through approved payment gateways.`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Customization Policy" />
                <Body
                  text={`Customized orders are non-cancellable once confirmed.`}
                />
                <Body
                  text={`Customers must provide clear instructions for customization during the order process.`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Replacement Policy" />
                <Body text={`Customers are eligible for a replacement if:`} />
                <Body
                  text={`1- The product delivered has a manufacturing defect.`}
                />
                <Body text={`2- A wrong item was shipped.`} />
                <Body
                  text={`Customers must notify us within 7 days of delivery and provide necessary proof (e.g., photos of the product).`}
                />
              </div>

              <div className="mb-10">
                <SubHeading text="Limitation of Liability" />
                <Body
                  text={`geniease is not liable for damages caused by misuse, neglect, or unauthorized alterations of the product post-delivery.`}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export function Heading({ text }) {
  return (
    <div className="text-4xl m-4 mx-10">
      <p>{text}</p>
    </div>
  );
}

export function SubHeading({ text }) {
  return (
    <div className="text-2xl m-4 mx-10">
      <p>{text}</p>
    </div>
  );
}

export function Body({ text }) {
  return (
    <div className="text-sm m-4 font-light mx-10">
      <p>{text}</p>
    </div>
  );
}

export default Terms;

// <SubHeading text="PRIVACY POLICY" />
//               <Body
//                 text={`This Privacy Policy is intended for all Users of geniease.com. geniease is dedicated to respecting and protecting the privacy of our Users. All information User provides, such as phone number, home address, current location, e-mail addresses or any additional personal information found on the site, will be used solely to support User relationship with geniease. geniease strives to develop innovative methods to serve Users even better. geniease is designed to operate efficiently while keeping user’s privacy in mind. This Privacy Policy outlines the types of personal information that geniease gathers from its users and takes steps to safeguard it. In order to provide a personalized browsing experience, geniease may collect information from you, which may include technical or other related information from the device used to access geniease platforms including without limitation to your current location. By registering or using or visiting geniease platforms, you explicitly accept, without limitation or qualification, the collection, use and transfer of the personal information provided by you in the manner described in the Terms & Conditions and Privacy Policy. Kindly read the Terms & Conditions and the Privacy Policy carefully as it affects your rights and liabilities under law. To use geniease it is mandatory to accept the Terms and Conditions and this Privacy Policy.
//               geniease reserves the right to take any rightful legal action against the customer if any fraudulent activity is identified such as multiple usage & abuse of coupon codes, wrong claims for orders etc.`}
//               />

//               <SubHeading text="USER’S CONSENT" />
//               <Body
//                 text={`By using or visiting geniease platforms, you expressly consent to the Terms and conditions and our Privacy Policy and to geniease processing of Personal Information for the purposes given under the Terms & Conditions and this Privacy Policy, subject to the local laws, in the following ways such as;
//               to create a personalized account containing your contact information, email-id, address, bank details etc based on information you provide or imported from other sites or applications, or any information provided by third parties;
//               to contact you about any Website updates, informational and service-related communications, including important security updates to inform you of other services available from geniease or its affiliates, if any. (“Affiliates” means entities that control or are controlled by or are under common control with geniease); to enable you to provide feedback, and contact geniease customer service in case of any problem with the services or emergency;
//               to conduct surveys, make promotions, advertise, and to provide the results thereof such as success stories, etc; to provide information about you and your experience to further promote our websites, etc.
//               to help your friends, contacts, and potential customers find your profile and connect with you;
//               to allow you to share your experience and information with your connections;
//               to give search engines access to public information; to detect, investigate and prevent activities that may violate our policies and are illegal; to provide certain information that enable users to network, post information on social media such as facebook, twitter, Instagram etc; to generate internal reports about the use of our platforms and many more related uses.
//               geniease uses the information provided by you to create its own database and networking, and may enable third parties to see your personal information for marketing purposes. geniease has certain hashtags such as #geniease which will be provided to you while you purchase a merchandize from geniease platforms. If you upload a picture on social media such as Facebook or Instagram using these hashtags, the geniease platforms will automatically link that picture to geniease platforms. The picture will be solely used for marketing and promotional purposes and shall be visible to anyone who uses the geniease platforms. Your Profile information may be used for networking with other community members or may be visible to service providers or customers who are using the Website. In addition, portions of your personal information may be made public for networking.
//               You acknowledge that by providing your personal information, you have consented to your information being used as described here. We shall take measures to safeguard your information from unauthorized access or inappropriate use by third parties. However, geniease has no control over third parties' websites and is not responsible for any sort of misuse or any other liability. You acknowledge that by linking with third party web sites, you are aware of the potential misuse and risk involved.`}
//               />

//               <SubHeading
//                 text="SECURITY
// "
//               />
//               <Body
//                 text={`You are responsible for ensuring that your login credentials (your username and password) are kept confidential.
//               In order to provide security measures, we have implemented technical and organizational measures specially designed to secure your personal information from accidental loss and unauthorized access, use, alteration or disclosure. However, due to the inherent open nature of the Internet and wireless communications, we do not provide guarantee that your personal information will be completely free from unauthorized access by third parties, because the transferring of money over or through geniease’s Platforms are not within our exclusive control.
//               Use of geniease services implies Users acknowledgement and assumption of this risk involved. geniease has made all reasonable efforts to put in place reasonable physical, electronic, and managerial procedures to safeguard the information we collect.
//               We seek to ensure compliance with the requirements of the Information Technology Act, 2000 as amended and rules made there to ensure the protection and preservation of your privacy. However, it is your responsibility to not provide any sensitive data that you would not want to make public. You should be aware that your account may be monitored by the customers and service providers across the Websites.
//               We also use cookies to administer our platforms and deliver a personalized experience. For additional detailed information on each of these, please see below.`}
//               />

//               <SubHeading text="COOKIES" />
//               <Body
//                 text={`“Cookies” are pieces of information that may be placed on User’s device by a service provider for the purpose of facilitating and enhancing User communication and interaction. To the extent possible depending upon User’s device, geniease may use cookies (and similar items such as web beacons, tags, etc.) to customize User experience, to make usage of Apps more convenient or to enable geniease to enhance its Services. geniease may also use and place cookies on User devices from our third-party service providers in connection with the User services, such as an analytics provider that helps User manage and analyze geniease Services. User may stop or restrict the placement of cookies on certain devices or flush them from User’s browser by adjusting User’s web browser preferences. However, User may still use geniease Services, but it may interfere with some of its functionality. Cookies and similar items are not used by geniease, and may automatically retrieve personal information from Users device without User’s knowledge. Cookies are device and browser specific, so if User makes a change to User’s environment geniease may reinstall a cookie.
//               We might utilize session ID cookies to enable certain features of the website, to better understand how you interact with the website, and to monitor aggregate usage by geniease users and web traffic routing on the Website. Unlike persistent cookies, session cookies are deleted from your computer/ device when you log off from the website and close your browser. Third-party advertisers on the website may also place or read cookies on your browser. You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the website you visit. If you do not accept cookies, however, you may not be able to use all portions of the website or Services.
//               We might utilize session ID cookies to enable certain features of the website, to better understand how you interact with the website and to monitor aggregate usage by geniease users and web traffic routing on the Website. Unlike persistent cookies, session cookies are deleted from your computer/ device when you log off from the website and close your browser. Third-party advertisers on the website may also place or read cookies on your browser. You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the website you visit. If you do not accept cookies, however, you may not be able to use all portions of the website or Services.
//               We might also use tracking technologies that may record information such as internet domain and host names; internet protocol (IP) addresses; browser software and operating system types; click stream patterns; dates and times; and location from which the website is accessed. Our use of cookies and other tracking technologies allows us to improve our website and your web experience. We may also analyze information that does not contain Personal Information for Trends and statistics.`}
//               />

//               <SubHeading text="CHANGES TO THIS PRIVACY POLICY:" />
//               <Body
//                 text={`geniease, reserves the right, at its discretion, to change, modify, add, or remove portions from this Privacy Policy at any time. However, if at any time in the future geniease plans to use personal information or location-based data in a way that materially differs from this Privacy Policy, such as sharing such information with more third parties, geniease, will post such changes here and provide Users the opportunity to opt-out of such differing uses. Users, continuing to use our services, following the posting of any changes to this Privacy Policy means User’s acceptance of such changes.`}
//               />

//               <SubHeading text="MODIFICATIONS TO THE SERVICE AND PRICES " />
//               <Body
//                 text={`Prices for our products are subject to change without notice.
//               We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
//               We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service. `}
//               />

//               <SubHeading text="PRODUCTS OR SERVICES (if applicable)" />
//               <Body
//                 text={`Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
//               We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
//               We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
//               We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected. `}
//               />

//               <SubHeading text="POLICY TOWARDS CHILDREN:" />
//               <Body
//                 text={`The Website is not directed to children under the age of 16 years. We do not knowingly collect personally identifiable information from individuals under the age of 16 years. We do not specifically collect information about children without parental consent or supervision. We encourage you to participate in your child’s experience with geniease.`}
//               />

//               <SubHeading text="INFORMATION WE COLLECT AND DATA RETENTION:" />
//               <Body
//                 text={`We commit to respecting your online privacy and data. We further recognize your need for appropriate protection and management of any personally identifiable information (“Personal Information”) you share with us. The Personal Information is used for such purposes as mentioned in this privacy policy. geniease shall collect from its User Private Information and Publicly Available information.
//               Private Information: Users full name, age, mobile number, permanent address, current location, e-mail address, bank details or any other information required for registering and creating and account and thereafter availing geniease’s services. We may share your Private Information only as described in this Privacy Policy and related documents.
//               Publicly-Available Information: The information made available to the public shall include:
//               Name (as registered).
//               Contact Information including email address, phone number and residential address.
//               geniease does not solicit any information provided by the User; however, if the information is required by any Central/State Authority for whatsoever purpose, information shall be shared by geniease, without seeking Users prior permission.
//               geniease may send User service-related emails (e.g., account verification, changes/ updates to features of the Service, technical and security notices).
//               We collect the following general types of information about you when you visit our Website: personal information, demographic information, behavioral information, and indirect information. In each case, however, it is indicated on the website whether any personal or demographic information must be provided to use the requested services or not. Demographic information is information that is not unique to you in the sense that it refers to selected population characteristics, such as your ZIP code or postal code, age, preferences, gender, race or ethnicity, occupation etc.
//               We also collect behavioural information regarding how you use our Website, the areas of our site that you visit, what services you access, geo-location, browser and operating system type, domain names, access times and referring Web Site addresses etc. This information is necessary for analyzing the use of resources, troubleshooting problems, preventing fraud, and improving our services.
//               We collect indirect information about the User when you use certain third party services on our WebSite and the collection of such information is considered necessary for that purpose.
//               We collect financial information such as Bank Account details or Credit Card /Debit Card details or other payment related details; solely for the purpose of making payment to the service providers for the services availed by you.
//               We do not collect sensitive personal information like physical and mental or other health conditions or medical records and history; Biometric information such as fingerprints, voice & facial patterns and DNA any other sensitive information which is confidential or sensitive by its nature.
//               We do not store information of users like the password of your email account or bank details. The password of your geniease account automatically gets saved in the geniease server when you create an account or register with geniease.
//               If you, choose to login with Facebook/ Google or similar sites, we import the information that you choose to share with us by installing the application or logging into that platform or Web Site, and we make it part of your account. Because geniease allows you to share your information we make certain information about you publicly available by default. Such information may, for example, be accessed by everyone on the Internet, including users without accounts, and may appear in public search engine search results.
//               We may personalize the advertisement shown on our websites. In order to provide this personalization, in addition to information we collect about you on our sites, we acquire information (including personal, demographic, behavioural and indirect information) about the User from third parties who provide it to us.
//               We may also ask for your feedback after the services you avail in an effort to deliver the best possible experience in future. We might communicate with you regularly with tips, advice and survey information on using the Website to improve our services. However, we provide you with the opportunity to opt-out of these types of communications. If you choose to receive these types of communications at the time of registration, but later decide to refrain from receiving these communications, you may simply log into your account and edit your account by changing the option to ‘Unsubscribe’.
//               We retain your personal information to further provide you with better services. If you wish to delete or close your account, please contact us at  ${ImpValues.supportMail}.  An email will be sent to you to confirm that your personal information and bank details have been deleted. A repository copy of an email will be retained only for as long as geniease reasonably considers necessary for its audit and record purposes. geniease shall also retain logs, demographic, indirect, and statistical information that may relate to you but do not identify you personally. If your personal information was previously accessed by others using our Sites, we are not able to delete the information or copies thereof from their systems.
//               If you do not want your information to be processed as described by this policy, you may revoke your consent to our Privacy Policy. If you wish to do so, please contact us. However, please note that if you do withdraw your consent, you may not be able to use the relevant services and your account will be deleted.
//               geniease holds the discretionary right to delete the data after the period of 60 days from the date of deletion of your account. Thereafter, geniease may either delete your personal information or de-identify it so that it is anonymous and not attributed to your identity. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with legal obligations.`}
//               />

//               <SubHeading text="NON-PERSONAL INFORMATION THAT GENIEASE COLLECTS:" />
//               <Body
//                 text={`geniease, automatically collects non-personal information from the User for the geniease Services, such as IP host address, device-specific identifiers, computer or smart phones configurations, number of times page has been viewed and usage habits of the geniease users or any similar data. It may be possible at times that while collecting non-personal information through automatic means, geniease may unintentionally collect or receive personal information that is mixed in with the non-personal information. While geniease will make reasonable efforts to prevent such incidental data collection, the possibility still exists. If geniease does inadvertently collect personal information, we will not intentionally use such personal information for any purposes. geniease shall not be responsible for the same if the information is collected unintentionally in such a scenario. geniease may also aggregate demographic information collected from the Users (such as the number of users in a particular State) in a manner which does not identify any one individual.`}
//               />

//               <SubHeading text="INFORMATION AND DATA USED:" />
//               <Body
//                 text={`Any of the information we collect from you may be used in one of the following ways but not restricted to:
//               To Improve Our Website and Foster Positive User Experience. We continually strive to improve our Website offerings based on the information and feedback we receive from you.
//               To Improve Services and Respond to Queries Raised. Your information helps us to more effectively respond to User’s service requests and support needs. The email address you provide may be used further to send you information and updates pertaining to your account or requests, in addition to receiving occasional geniease news, updates, related product or service information, etc.
//               Legal Purposes: We may share your information when we believe in good faith that such sharing is reasonably necessary in order to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. We may also share your information to investigate and address threats or potential threats for the safety of public or any person, to investigate and address violations of this Privacy Policy or the Terms of Service, or to investigate and address violations of the rights of third parties and/or to protect the rights, property and safety of geniease users, employees, or public. This may involve the sharing of your information with Law enforcement, Government Agencies, Courts, and/or other organizations on account of legal requests such as summons, court order or Government demand to comply with the Law.
//               Others: If we believe that disclosure is necessary or appropriate to protect the rights, property, or safety of geniease, its User’s or any other person for that matter. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.`}
//               />

//               <SubHeading text="INFORMATION SHARING AND THIRD PARTY DISCLOSURES:" />
//               <Body
//                 text={`geniease uses the information we gather on the Web Site, whether personal, demographic, collective or technical, for the purposes of operating and improving the Web Site, fostering a positive user experience, and delivering the products and services that we offer.
//               If you have provided consent to do so, we may also use the information gathered to inform you of other services available or our affiliated companies or to contact you for receiving feedback regarding current services or potential and services that may be offered. We may also provide additional communications and information on new services which may be of interest to you. The nature and frequency of these messages will vary depending upon the information we have about you. However, you may choose to refrain from receiving the same while opting out at the time of registration or may be later as and when you decide to do so.
//               Although we are committed to protecting your privacy, we do not promise, and you should not expect, that your personal information or private communications will always remain private. It may be shared to third parties as and when required. As a user of the platforms, understand and agree that you assume all responsibility and risk for your use of the platforms, the internet generally, and the documents you post or access and for your conduct on and off the Websites.
//               We may share your information with third parties who help us in the delivery of our services to you. We disclose information to companies and individuals we employ to perform functions on our behalf.
//               We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of geniease. Terms of Use, or as otherwise required by law.
//               We transfer information about you if geniease is acquired by or merged with another company. In this event, we will notify you before transferring or disclosing your personal information.
//               geniease may or may not provide links to third party services from within geniease Service. geniease is not responsible for the privacy practices or the content of these third party services and such links should not be construed as an endorsement of any such third party services by us.
//               geniease shall not be responsible for any act of the user’s or third parties which is contrary to the Terms of Use or Laws. geniease shall not be held responsible for the illegal act of the user towards the other.`}
//               />

//               <SubHeading text="ACCESS TO PERSONAL INFORMATION:" />
//               <Body
//                 text={`The User can gain access to the Personal Information you have provided by visiting our Website/Apps. If you need any assistance with respect to Log In, you can send us an email on  ${ImpValues.supportMail}. In order to inquire about the privacy setting of your personal information with us, contact the relevant Officer/Authority on  ${ImpValues.supportMail}
//               geniease retains indefinitely all the information we gather about you in an effort to make your repeat use of the Web Site more efficient, practical and relevant. You can delete your account from geniease's online database, at any time. This will remove all your personal details and bank account information with us.`}
//               />

//               <SubHeading text="INDEMNIFICATION" />
//               <Body
//                 text={`You agree to indemnify, defend and hold harmless geniease and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party. `}
//               />

//               <SubHeading text="OPT-OUT PROCESS:" />
//               <Body
//                 text={`The User may unsubscribe from geniease advertisement/marketing messages. All unsubscribe or opt-out requests should be sent to us at  ${ImpValues.supportMail} and geniease, will process the Users request within a reasonable time after receipt. However, geniease is not responsible for removing Users personal information from the lists of any third party to whom User has shared information at his/her own discretion. The User should contact such third parties directly and if the user does contact, we assume that you have acknowledged all the risks involved therein.`}
//               />

//               <Heading text="For Made to Order/Customized products" />
//               <Body
//                 text={`We are unable to offer an exchange or a return for the order because all
//               made-to-order/Customized products are distinctive & Special to meet the unique specifications
//               provided to us by our clients.`}
//               />

//               <SubHeading
//                 text={`
//             For Other Products & Accessories
//             `}
//               />

//               <Body
//                 text={`Return of any order (Applicable on the product) is applicable within 7 days of receiving the
//               order. The shipping price is not refundable.
//               For Cancellation of any order, please reach us via the contact us link.
//               Cancellation is only possible in case if the product has not shipped. Once shipped, cancellation
//               requests are not valid.`}
//               />
//               <Body
//                 text={`Please note that ₹ 100 reverse shipment charges are applicable in case of pick up is done
//             by us.`}
//               />

//               <SubHeading
//                 text={`
//               SELF-SHIPPING OF ORDERS FOR RETURNS:
//             `}
//               />

//               <Body
//                 text={`In case your pincode is non-serviceable for a reverse pick up, you’ll have courier the
//               product(s) to the following address: geniease Warehouse- Main Market, Old
//               Faridabad, Haryana-121002
//               Please ensure the items are packed securely to prevent any loss or damage during transit and
//               the ORDER ID and registered mobile number is mentioned on the packaging. All items must be
//               in unused condition with all original tags attached and packaging intact.
//               `}
//               />
//               <Body
//                 text={`
//               PLEASE NOTE: We request that you do not use The Professional Couriers for self
//               return as they are not reliable and the package will not be accepted at the warehouse.
//               Please make sure your courier costs do not exceed the amount stipulated above. We
//               recommend using ‘Speed Post’ as your courier service. Speed Post is a Government of
//               India owned entity and has the most widely distributed postal network in India.`}
//               />

//               <SubHeading
//                 text={`
//               TERMS AND CONDITIONS
//             `}
//               />

//               <Body
//                 text={`Bank account details provided by the customer would be final and any error in the details
//               provided by the customer would not be the responsibility of the brand.
//               `}
//               />
//               <Body
//                 text={`
//                If you have received an SMS or EMAIL of order delivered status and if it's not received
//               by you, please email or contact us within 24 hours.
//               `}
//               />
//               <Body
//                 text={`
//                email: ${ImpValues.supportMail}
//               `}
//               />
//               <Body
//                 text={`
//                phone no.: ${ImpValues.contactNumber}
//               `}
//               />
//               <Body
//                 text={`
//                The customer will be under an obligation to take utmost care of the product(s) whilst the
//               products are in their possession. This includes all of the product(s) instructions,
//               documents and wrappings while returning the product(s) in the same condition as
//               received.
//               `}
//               />
//               <Body
//                 text={`
//                In circumstances where the customer feels that the product does not conform to the
//               standards at the time of delivery, they shall promptly contact us via our mail
//               ${ImpValues.supportMail} with details of the product and its damage within 1 day of
//               receiving the products; whereon, the customer will receive instructions from us.
//               `}
//               />
//               <Body
//                 text={`
//                Upon return of the product, we will fully examine it and notify the customer of their right
//               to a refund (if any) via e-mail within a reasonable period. We aim to process the refund
//               as soon as possible and, in any case, within 30 working days from the day, we confirmed
//               to the customer via e-mail that they are entitled to a refund.`}
//               />

//               <SubHeading
//                 text={`
//               Shipping & Delivery
//             `}
//               />

//               <Body
//                 text={`We are committed to deliver your order with good quality packaging within a given time frame.
//               We ship throughout the week, except Sundays & Public holidays. To ensure that your order
//               reaches you in good condition, in the shortest span of time, we ship with major courier partners
//               like Delhivery, Xpressbees, Bluedart & DTDC.
//               Upto 3 delivery attempts are made by the courier partner. In case the delivery is not executed
//               during all the three attempts, due to the recipient not available / premises locked, etc., the
//               customer shall still be charged for the delivery.`}
//               />

//               <SubHeading
//                 text={`
//               How long does it take for an order to arrive?
//             `}
//               />

//               <Body
//                 text={`Orders are dispatched within 3-4 working days, unless it is a customized order in that case it
//               can take upto 5 working days. Most orders are delivered within 2 to 6 working days post
//               dispatch unless there is reason which is beyond control. Delivery of all orders will be duly done
//               to the address as mentioned by you at the time of placing the order.`}
//               />

//               <SubHeading
//                 text={`
//               Items Out Of Stock
//             `}
//               />

//               <Body
//                 text={`If an item is out of stock, we will cancel and refund the out-of-stock items and dispatch the rest
//               of the order.`}
//               />
