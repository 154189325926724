import React, { useState } from "react";
import NavbarAndFooterComp from "../../../components/NavbarAndFooterComp";
import SelectInput from "../../../components/TextInput/SelectInput";
import {
  DeliveryStatusesFilter,
  deliveryStatusFilterValues,
} from "../../../constants/DeliveryStatuses";
import RouteWaiter from "../../../components/RouteWaiter";

import TextComp from "../../../components/TextComp";
import InfiniteScrollComp from "../../../components/InfiniteScrollComp";

import {
  orderStatusFilter,
  orderStatusFilterValues,
} from "../../../constants/OrderStatus";
import {
  paymentStatusFilter,
  paymentStatusFilterValues,
} from "../../../constants/PaymentStatus";
import UserDetails from "./UserDetails";
import ProductDetails from "./ProductDetails";
import DeliveryDetails from "./DeliveryDetails";
import PaymentDetails from "./PaymentDetails";
import OrderDetails from "./OrderDetails";
import CancellationDetails from "./CancellationDetails";
import ReturnDetails from "./ReturnDetails";
import {
  returnCancelFilterStatus,
  returnCancelStatusFilterValues,
} from "../../../constants/ReturnCancelStatus";

function AllOrders() {
  const [orderStatus, setOrderStatus] = useState(orderStatusFilterValues?.all);
  const [deliveryStatus, setDeliveryStatus] = useState(
    deliveryStatusFilterValues?.all
  );
  const [paymentStatus, setPaymentStatus] = useState(
    paymentStatusFilterValues?.All
  );
  const [returnCancelStatus, setReturnCancelStatus] = useState(
    returnCancelStatusFilterValues?.all
  );

  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  return (
    <NavbarAndFooterComp
      children={
        <div className="mx-5 md:mx-10 py-10">
          <div className="flex">
            <div className="m-2 flex-grow w-1/2">
              <SelectInput
                label={"Search by order status"}
                values={orderStatusFilter}
                // id="title"
                onChange={(e) => setOrderStatus(e.target.value)}
                defaultValue={orderStatus}
              />
            </div>

            <div className="m-2 flex-grow w-1/2">
              <SelectInput
                label={"Search by delivery status"}
                values={DeliveryStatusesFilter}
                // id="title"
                onChange={(e) => setDeliveryStatus(e.target.value)}
                defaultValue={deliveryStatus}
              />
            </div>
          </div>

          <div className="flex">
            <div className="m-2 flex-grow w-1/2">
              <SelectInput
                label={"Search by payment status"}
                values={paymentStatusFilter}
                // id="title"
                onChange={(e) => setPaymentStatus(e.target.value)}
                defaultValue={paymentStatus}
              />
            </div>

            <div className="m-2 flex-grow w-1/2">
              <SelectInput
                label={"Search by return/cancel status"}
                values={returnCancelFilterStatus}
                // id="title"
                onChange={(e) => setReturnCancelStatus(e.target.value)}
                defaultValue={returnCancelStatus}
              />
            </div>
          </div>

          <div>
            <InfiniteScrollComp
              url="/getAllOrderCollection"
              responseDataName="orderData"
              params={{
                orderStatus,
                deliveryStatus,
                paymentStatus,
                returnCancelStatus,
              }}
              setLoading={setLoading}
              setErrorText={setErrorText}
              data={orderData}
              setData={setOrderData}
              valueOfUseEffectArray={[
                orderStatus,
                deliveryStatus,
                paymentStatus,
                returnCancelStatus,
              ]}
              children={
                <RouteWaiter
                  loading={loading}
                  error={errorText}
                  data={orderData}
                  children={
                    <div>
                      {orderData?.map((item, index) => (
                        <CardsComp
                          item={item}
                          key={index}
                          setLoading={setLoading}
                          setOrderData={setOrderData}
                          index={index}
                        />
                      ))}
                    </div>
                  }
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}

export const FlexDiv = ({ title, value }) => {
  return (
    <div className="flex flex-row items-center justify-between text-sm tracking-wide font-medium mb-2">
      <TextComp text={title} />
      <TextComp text={value} />
    </div>
  );
};

const CardsComp = ({ item, setLoading, setOrderData, index }) => {
  return (
    <div className="bg-lightGray shadow my-10 py-5 px-5 cursor-pointer rounded">
      <UserDetails item={item} />
      <ProductDetails item={item} />
      <DeliveryDetails
        item={item}
        setLoading={setLoading}
        setOrderData={setOrderData}
        index={index}
      />
      <PaymentDetails
        item={item}
        setLoading={setLoading}
        setOrderData={setOrderData}
        index={index}
      />
      <OrderDetails
        item={item}
        setLoading={setLoading}
        setOrderData={setOrderData}
        index={index}
      />

      {item?.cancellationData ? (
        <CancellationDetails
          item={item}
          setLoading={setLoading}
          setOrderData={setOrderData}
          index={index}
        />
      ) : null}
      {item?.returnData ? (
        <ReturnDetails
          item={item}
          setLoading={setLoading}
          setOrderData={setOrderData}
          index={index}
        />
      ) : null}
    </div>
  );
};

export default AllOrders;
