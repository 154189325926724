import React from "react";
import Loader from "../Loader";
import SWWComp from "../BlankScreen/SWWComp";
import NoDataComp from "../BlankScreen/NoDataComp";

function RouteWaiter({
  loading,
  error = "",
  errorChildren = <SWWComp />,
  data,
  children,
  noDataChildren = <NoDataComp />,
  loaderComp = null,
  showNoDataAndError = true,
}) {
  return (
    <div>
      {loading === true && (!data || data?.length === 0)
        ? loaderComp
          ? loaderComp
          : null
        : (showNoDataAndError === true && error !== "") || error === null
        ? errorChildren
        : (showNoDataAndError === true && !data) || data?.length === 0
        ? noDataChildren
        : children}

      {loaderComp ? null : <Loader loading={loading} />}
    </div>
  );
}

export default RouteWaiter;
