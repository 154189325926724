export const deliveryStatusValues = {
  ordered: "ordered",
  packed: "packed",
  intransit: "intransit",
  delivered: "delivered",
};

export const DeliveryStatuses = [
  {
    label: "ORDERED",
    value: deliveryStatusValues.ordered,
  },
  {
    label: "PACKED",
    value: deliveryStatusValues.packed,
  },
  {
    label: "IN TRANSIT",
    value: deliveryStatusValues.intransit,
  },
  {
    label: "DELIVERED",
    value: deliveryStatusValues.delivered,
  },
];

export const deliveryStatusFilterValues = {
  ordered: "ordered",
  packed: "packed",
  intransit: "intransit",
  delivered: "delivered",
  all: "all",
};

export const DeliveryStatusesFilter = [
  {
    label: "All",
    value: deliveryStatusFilterValues.all,
  },
  {
    label: "ORDERED",
    value: deliveryStatusFilterValues.ordered,
  },
  {
    label: "PACKED",
    value: deliveryStatusFilterValues.packed,
  },
  {
    label: "IN TRANSIT",
    value: deliveryStatusFilterValues.intransit,
  },
  {
    label: "DELIVERED",
    value: deliveryStatusFilterValues.delivered,
  },
];

export const returnStatusValues = {
  return: "return",
  exchange: "exchange",
  cancelled: "cancelled",
};

export const ReturnStatuses = [
  {
    label: "RETURNED",
    value: returnStatusValues.return,
  },
  {
    label: "EXCHANGED",
    value: returnStatusValues.exchange,
  },
  {
    label: "CANCELLED",
    value: returnStatusValues.cancelled,
  },
];

export const CustomisationStatusesValues = {
  pending: "pending",
  indiscussion: "indiscussion",
  inprocess: "inprocess",
  intransit: "intransit",
  delivered: "delivered",
  cancel: "cancel",
};

export const CustomisationStatuses = [
  {
    label: "PENDING",
    value: CustomisationStatusesValues.pending,
  },
  {
    label: "IN DISCUSSION",
    value: CustomisationStatusesValues.indiscussion,
  },
  {
    label: "In PROCESS",
    value: CustomisationStatusesValues.inprocess,
  },
  {
    label: "IN TRANSIT",
    value: CustomisationStatusesValues.intransit,
  },
  {
    label: "DELIVERED",
    value: CustomisationStatusesValues.delivered,
  },
  {
    label: "CANCELLED",
    value: CustomisationStatusesValues.cancel,
  },
];

export const CustomisationStatusesValuesFilter = {
  pending: "pending",
  indiscussion: "indiscussion",
  inprocess: "inprocess",
  intransit: "intransit",
  delivered: "delivered",
  cancel: "cancel",
  all: "all",
};

export const CustomisationStatusesFilter = [
  {
    label: "PENDING",
    value: CustomisationStatusesValuesFilter.pending,
  },
  {
    label: "IN DISCUSSION",
    value: CustomisationStatusesValuesFilter.indiscussion,
  },
  {
    label: "In PROCESS",
    value: CustomisationStatusesValuesFilter.inprocess,
  },
  {
    label: "IN TRANSIT",
    value: CustomisationStatusesValuesFilter.intransit,
  },
  {
    label: "DELIVERED",
    value: CustomisationStatusesValuesFilter.delivered,
  },
  {
    label: "CANCELLED",
    value: CustomisationStatusesValuesFilter.cancel,
  },
  {
    label: "All",
    value: CustomisationStatusesValuesFilter.all,
  },
];
